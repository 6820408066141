export default {
  tabbar: {
    首页: 'Home',
    市场: 'Market',
    任务: 'Task',
    团队: 'Team',
    我的: 'My'
  },
  navbar: {
    充值: 'Recharge',
    提现: 'Withdrawal',
    帮助: 'Help',
    分享: 'Share',
    活动: 'Events'
  },	 
  payment: {
    付款方式详情: 'Payment method details',
    银行卡: 'bank card',
    钱包地址: 'Wallet address',
    交易密码: 'Transaction password',
    保存: '保存'
  },
  market: {
    市场: 'Market',
    名称: 'Name',
    价格: 'Price',
  },
 
  task: {
    任务: 'task',
	语言切换: 'Language switching',
    我的余额: 'My balance',
    增加订单数量: 'Increase order quantity',
    今日剩余订单: 'Remaining orders today',
    频率: 'frequency',
    手动交易: 'Manual trading',
    预期收益: 'Expected income',
    需要启动存款: 'Need to start deposit',
    任务说明: 'Task description',
    每天可以完成10个任务: 'Can complete 10 tasks per day',
    联系VIP助手增加任务数量: 'Contact the VIP assistant to increase the number of tasks',
    每个用户的任务总数为300次: 'The total number of tasks per user is 300',
    购买机器人无需人工操作即可自动交易: 'Automatic transaction without manual operation when purchasing robots',
    我知道了: 'I got it!'
  },
  团队: 'Team',
  规则: 'Rule',
  银行:"Bank",
  会员级别: 'Member level',
  经验: 'Experience',
  代理信息: 'Agent information',
  更多: 'More',
  团队奖励: 'Team rewards',
  昨天: 'Yesterday',
  本星期: 'This week',
  全部: 'All',
  团队收入记录: 'Team revenue records',
  机器人回扣: 'Robot rebate',
  会员体验: 'Member experience',
  个人收入记录: 'Personal income records',
  机器人采购: 'Robot procurement',
  机器人启动押金: 'Robot startup deposit',
  机器人礼物: 'Robot gift',
  机器人订单: 'Robot order',
  机器人: 'Robot',
  一次性总和: 'Lump sum',
  总计: 'Total',
  我的机器人: 'My robot',
  被激活: 'Activated',
  工作中: 'Working',
  暂停: 'Stop',
  结束: 'End',
  刷新: 'Refresh',
  来源: 'Source',
  价格: 'Price',
  启动押金: 'Start price',
  剩余时间: 'Time remaining',
  激活时间: 'Activation time',
  号: 'No.',
  我的资产: 'My assets',
  退出: 'Log out',
  邀请码: 'Invite Code',
  我的钱包余额: 'My wallet balance',
  我的机器人仓库: 'My robot warehouse',
  代理信息: 'Agent information',
  去提款: 'Withdrawal',
  昨天代理返利: 'Agency rebate yesterday',
  累计代理返利: 'Accumulated agency rebate',
  今天机器人收益: 'Today revenue',
  累计机器人收益: 'Accumulated income',
  累计机器人回扣: 'Accumulated rebate',
  累计总回报: 'Cumulative total return',
  自动订单机器人数量: 'Number of automatic order robots',
  我的付款方式: 'My payment method',
  个人收入记录: 'Personal income records',
  团队收入记录: 'Team revenue records',
  充值记录: 'Recharge record',
  我的团队成员: 'My team members',
  活动中心: 'Events Center',
  修改登录密码: 'Modify login password',
  交易密码管理: 'Transaction password management',
  机器人: 'Robot',
  机器人性能: 'Robot performance',
  机器人价格: 'Robot price',
 
  有效期: 'Validity',
  
  天: 'Days',
  去购买: 'To buy',
  买个机器人: 'Buy robot',
 
  预计日收益: 'Estimated daily income',
  启动保证金: 'Start up deposit',
  请输入购买数量: 'Please enter the purchase quantity',
  个机器人: ' PCs. / robot',
  机器人购买后需要激活才能工作: 'The robot needs to be activated to work after purchase',
  机器人激活需要从余额中扣除相应的启动押金:
    'The robot activation needs to deduct the corresponding startup deposit from the balance',
  机器人暂停机器人停止时将返还启动押金:
    'When the robot stops, the startup deposit will be returned',
	不激活的机器人可以赠送:"Inactive robots can be given away",
  购买: 'Buy',
  点击去了解我们: 'Click to learn about us',
  购买机器人: 'Buy robot',
  快速入口: 'Quick entry',
  '日/个人受益': 'Daily / personal benefit',
  购买教程: 'Purchase tutorials',
  订单数量: 'Order quantity',
  我们的合作伙伴: 'Our partners',
  提款: 'Withdrawal',
  提款金额: 'Withdrawal Amount ',
  你还没有添加银行卡: 'You have not added a bank card',
  提现说明: 'Withdrawal description',
  确认提款: 'Confirm withdrawal ',
  钱包余额: 'Wallet balance',
  输入数量:"Enter USDT amount",
  数量:"Amount",
  登录: 'Login',
  输入账号: 'Enter account number',
  输入密码: 'Input password',
  还没有账号点击注册: 'No account? Click Register',
  忘记密码: 'Forgot password',
  重新登录: 'Log in again',
  密码找回成功: 'Password retrieved successfully',
  找回成功请重新登录: 'Successfully retrieved, please log in again',
  确认: 'confirm',
  发送验证码: 'Send verification code',
  再次输入密码: 'Enter the password again',
  输入验证码: 'Enter verification code',
  输入手机号: 'Enter mobile number',
  注册: 'Register',
  输入用户名: 'enter one user name',
  请输入邀请码: 'Please enter the invitation code',
  返回: 'Return',
  密码找回失败: 'Password retrieval failed',
  输入的信息有误: 'Incorrect information entered',
	
	获取验证码:"Get code",
	手机号码不能为空:"Mobile number cannot be empty",
	账号不存在:"Account does not exist",
	发送成功:"Sent successfully",
	今日收益:"Today earnings",
	
	日收益:"Daily income",
	
	数量不能为空:"Quantity cannot be empty",
	加载中:"Loading",
	唯一码:"Unique code",
	未激活:"Not active",
	激活:"Activation",
	购买时间:"Purchase time",
	"钱包余额不足，激活该机器人失败":"Insufficient wallet balance, failed to activate the robot",
	暂无数据:"No data...",
	激活时间:"Activation time",
	运行时长:"Run time",
	签约购买:"Contract purchase",
	系统赠送:"System gift",
	状态:"State",
	正常:"Normal",
	我的机器人:"My robot",
	一级会员:"Level1",
	二级会员:"Level2",
	三级会员:"Level3",
	人:"People",
	充值客服:"Recharge customer service",
	充值:"Recharge",
	提现:"Withdrawal",
	设置交易密码:"Set transaction password",
	登录密码:"Login password",
	请输入交易密码:"Please enter transaction password",
	再次请输入交易密码:"Please enter your transaction password again",
	确认: 'Confirm',
	手机号码不能为空:"Mobile number cannot be empty",
	两次密码不一致:"The two passwords are inconsistent",
	请输入验证码:"Please enter the verification code",
	操作成功:"Operation is successful",
	"用户名或密码不正确,登录失败":"Incorrect user name or password, login failed",
	登录成功:"Login successful",
	充值说明:"Recharge instructions",
	请先设置支付密码:"Please set the payment password first",
	复制成功:"Copy successful",
	冻结机器人做单本金:"Freezing the principal of robot orders",
	待审核:"To be reviewed",
	成功:"Success",
	失败:"Fail",
	审核中:"Under review",
	在线充值:"Online recharge",
	描叙:"describe",
	银行卡提现:"Bank card withdrawal",
	USDT提现:"USDT Withdrawal",
	三级代理:"Third level agent",
	一级代理:"Primary agent",
	二级代理:"Secondary agent",
	做单:"Make an order",
	团队返佣: "Team rebate",
	购买机器人返佣:"Buy robot rebate",
	本金返还:"Return of principal",
	时间:"Time",
	机器人做单返还:"Robot makes order and returns",
	涨幅:"Increase",
	
  付款方式:"Payment method",
	我的留言:"My message",
  
  银行卡:'Bank card',
  修改: 'modify',
 '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'Make sure the wallet address is correct, so as to prevent the loss caused by users who cannot receive money due to wrong address input',
 连接借记卡:"Connect debit card",
 银行名称:'Bank name',
 姓名姓氏: 'Name',
 IBAN号码:'Bank card No',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* Important: debit card information must be true and valid before withdrawal.',
 帮助中心:'Help center',
 会员须知:'Notes to members',
 '24小时内使用USDT实时取款（推荐）':'1. Real time withdrawal with usdt within 24 hours (recommended)',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2. bank card withdrawal * withdrawal time is 10:00-18:00 * account entry within 2 hours after the withdrawal is initiated * application for non withdrawal time or inter-bank withdrawal will be received at 10:00-18:00 the next day',
 我知道了:'I got it!',
 链接点击:'Link Click',
 
 建立自己的团队邀请:'Create your own team invitation',

 加入:'join',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "The robot you sold has been accepted by the other party. The sale proceeds have been paid to your account. Please check.",
 	"你出售的机器人对方已拒绝接受":"The robot you sold has been rejected by the other party",
 	向你赠送一台机器人:"A robot is presented to you for confirmation",
 	向你出售一台机器人:"Sell you a robot, to be confirmed",
 	出售价格不能超过机器人价格:"The selling price cannot exceed the robot price",
 	银行卡已被绑定:"Bank card has been bound",
 	USDT_OMNI提现:'USDT_OMNI Withdrawal',
 	USDT_ERC20提现:'USDT_ERC20 Withdrawal',
 	银行卡提现:'Bank card withdrawal',
 	邀请赠送:'Invitation gift',
 	机器人返佣:'Robot Commission',
 	升级:'upgrade',
 	充值主网:'Recharge main network',
   "您被限制出售机器人,请联系客服了解详情":
     "You are restricted from selling robots. Please contact customer service for details",
   交易编号: "Transaction No",
   消息详情:"Message details",
   个人收益累计:"Accumulated personal income",
   今日代理收益:"Agency income today",
   代理累计收益:"Accumulated income from agency",
  
   截图保存推荐给朋友:"Save screenshots and recommend to friends",
   复制:"copy",
   
   请输入正确的充值金额:"Please enter the correct recharge amount",
   推荐:"recommend",
   充值金额:"Recharge amount",
   请上传付款截图:"Please upload the payment screenshot",
   充值ID提示:"Recharge ID prompt",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Please ensure that the wallet address is correct. If the wallet address is filled in incorrectly, the user will bear the losses caused by the inability to collect money.",
	上传凭证:"Upload voucher",
	不能为空:"Cannot be empty",
  绑定钱包地址:'Bind wallet address',
	
	备注:"Remarks",
	请输入你的名字:"Please enter your name",
	请输入银行卡号:"Please enter the bank card number",
	银行卡号:"Bank card No",
	添加银行卡:"Add bank card",
	请选择银行:"Please select a bank",
	请输入钱包地址:"Please enter the wallet address",
	钱包地址:"Wallet address",
	"取款须知":"Instructions for withdrawal",
	"须知1":"1. Real time withdrawal with usdt within 24 hours (recommended)",
	"须知2":"2.bank card",
	"须知3":"Withdrawal * withdrawal time is 10:00-18:00",
	"须知4":"*The application for non withdrawal time or inter-bank withdrawal will be received at 10:00-18:00 the next day",
	
	已绑定:"Bound",
	去设置:"Setting",
	汇率:"exchange rate",
	请输入正确的提现金额:"Please enter the correct withdrawal amount",
	提款记录:"Withdrawal record",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:The bound wallet address must be consistent with the payment wallet, otherwise it will not be received。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:It is expected to arrive in about one minute after the successful recharge. Please check your wallet.",
	请先绑定钱包地址:"Please bind the wallet address first",
	输入邮箱:"Enter Gmail",
	指定用户不存在:"specified user does not exist",
	今日涨幅:"Increase",
	快捷充币:"Quick Deposit",
	快速买USDT:"Quick buy USDT",
	在线支付:"Online payment",
	自动到账:"Automatic receipt",
	线下支付:"Offline payment",
	联系客服获取验证码:"Contact customer service for verification code",
	获取:"Get",
	重新登录:"Log in again",
	退出成功:"Exit successful",
	不能低于最小充值:"Cannot be lower than the minimum recharge",
	不能低于最小提现金额:"Not less than the minimum withdrawal amount",
	最小提现:"Minimum withdrawal",
	官方充币:"Official charging",
	银行卡充值:"Bank card recharge",
	等待审核:"Pending review",
	可提现金额不足:"Insufficient withdrawal amount",
	未经授权:"unaccredited",
	交易密码不正确:"Incorrect transaction password",
	提现次数不足:"Insufficient withdrawal times",
	需要更新的银行卡不存在:"The bank card to be updated does not exist",
	银行卡提现:"Bank card withdrawal",
	激活金额:"Activation amount",
	'赠送/出售':"Give / Sale",
	赠送:"Give",
	出售:"Sale",
	账户usdt不足:"Lack of balance",
	请输入提现金额:"Please enter the withdrawal amount",
	恭喜新用户:"Congratulations to new users",
	kefu1:"Youtube video tutorial",
	登录密码:"Login password",
	再次请输入交易密码:"Enter the transaction password again",
	输入手机号码:"Phone number",
	输入手机号码:"Enter phone number",
	输入密码:"Password",
	请输入密码:"Enter password",
	手机号格式错误:"Mobile number format error",
	"密码错误次数过多,请等待x秒后再试":"Too many password errors,Please wait 600 seconds and try again",
	"此账号已冻结,请联系客服":"This account has been frozen, please contact customer service",
	登录失败:"Login failed",
	请勿重复操作:"Do not repeat",
	邀请码不正确:"Incorrect invitation code",
	此账号已冻结:"This account has been frozen",
	请输入手机号:"Please enter your mobile number",
	验证码错误:"Verification code error",
	手机号存已存在:"Mobile phone number already exists",
	注册账号已存在:"Registered account already exists",
	请确定新密码:"Please confirm the new password",
	请再次输入登录密码:"Please enter your login password again",
	密码长度不能少于6位:"Password length cannot be less than 6 digits",
	
	输入邮箱:"Enter Gmail",
	注册邮箱已存在:"Registration Gmail already exists",
	请输入用户ID:"Enter recipient's GMAIL",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":
	"Please carefully review the information if you enter the account name of the other party and transfer the property. If there is an operation error, it cannot be retrieved through the system or the platform customer service.",
	请输入出售金额:"Please enter the sale amount",
	出售中:"Gift has been submitted, waiting for confirmation",
	 收到一台系统赠送的机器人 :"Received a robot from the system",
	 会员出售:"Members sell",
	 "出售待确认中,请勿重复操作":"Sale pending confirmation, do not repeat operation",
	 激活成功:"Activate the success",
	 操作成功:"Operation is successful",
	
	输入邮箱:"Enter Gmail",
	
	请输入登录密码:"Please enter your login password",
	未满足条件:"Condition not met",
	活动已过期:"Activity expired",
	个人充值奖励活动:"A: Personal deposit bonus event",
	"邀请好友注册，领取佣金":"B: Invite friends to register and receive commission",
	领取:"To receive",
	团队规则:"Team rule",
	邮箱已存在:"The Gmail already exists",
	获取邮箱验证码:"Get code",
	请输入邮箱:"Please enter email address",
	手机号码:"Phone",
	邮箱不能为空:"Email cannot be empty",
	"出售/赠送":"Sale / Gift",
	已接受:"Accepted",
	"出售/赠送人":"Seller / giver",
	"向你赠送一台机器人，待确认":"A robot is presented to you for confirmation",
	国码:"Country code",
	取消:"Cancel",
	银行卡号位数错误:"Wrong number of bank cards",
	官网:"Official site",
	注册成功:"Login successful",
	设置成功:"Set successfully",
	手机号码已存在:"Mobile number already exists",
	修改成功:"Modified successfully",
	立即登录:"Already have an account, click to log in",
	客服:"Chat",
	邮箱:"Email",
	机器人量化AI交易收益:"Robot quantifies AI trading income",
	体验机器人不能赠送:"Experience robots cannot be given away",
	接受:"Accept",
	拒绝:"Refuse",
	语言切换:"Language switching",
}
