export default {
  tabbar: {
    首页: 'หน้าแรก',
    市场: 'มาร์เก็ต',
    任务: 'ภาระกิจ',
    团队: 'ทีม',
    我的: 'ของฉัน'
  },
  navbar: {
    充值: 'เติมเงิน',
    提现: 'การถอนเงิน',
    帮助: 'ช่วย',
    分享: 'แบ่งปัน',
    活动: 'งานอีเว้นท์'
  },	 
  payment: {
    付款方式详情: 'รายละเอียดวิธีการชำระเงิน',
    银行卡: 'บัตรธนาคาร',
	银行:"ธนาคาร",
    钱包地址: 'ที่อยู่กระเป๋าเงิน',
    交易密码: 'รหัสผ่านการทำธุรกรรม',
    保存: 'บันทึก'
  },
  market: {
    市场: 'มาร์เก็ต',
    名称: 'ชื่อ',
    价格: 'ราคา',
  },
 
  task: {
    任务: 'ภาระกิจ',
	语言切换: 'การสลับภาษา',
    我的余额: 'ยอดคงเหลือของฉัน',
    增加订单数量: 'เพิ่มปริมาณการสั่งซื้อ',
    今日剩余订单: 'ออเดอร์ที่เหลือวันนี้',
    频率: 'ความถี่',
    手动交易: 'การซื้อขายด้วยตนเอง',
    预期收益: 'รายได้ที่คาดหวัง',
    需要启动存款: 'ต้องเริ่มฝากเงิน',
    任务说明: 'คำอธิบายงาน',
    每天可以完成10个任务: 'ทำได้ 10 งานต่อวัน',
    联系VIP助手增加任务数量: 'ติดต่อผู้ช่วย VIP เพื่อเพิ่มจำนวนงาน',
    每个用户的任务总数为300次: 'จำนวนงานทั้งหมดต่อผู้ใช้คือ 300',
    购买机器人无需人工操作即可自动交易: 'ธุรกรรมอัตโนมัติโดยไม่ต้องดำเนินการด้วยตนเองเมื่อซื้อหุ่นยนต์',
    我知道了: 'ฉันเข้าใจแล้ว!'
  },
  团队: 'ทีม',
  规则: 'กฎ',
  会员级别: 'ระดับสมาชิก',
  经验: 'ประสบการณ์',
  代理信息: 'ข้อมูลตัวแทน',
  更多: 'เพิ่มเติม',
  团队奖励: 'รางวัลทีม',
  昨天: 'เมื่อวาน',
  本星期: 'ในสัปดาห์นี้',
  全部: 'ทั้งหมด',
  团队收入记录: 'บันทึกรายได้ของทีม',
  机器人回扣: 'คอมมิชชั่นหุ่นยนต์',
  会员体验: 'ประสบการณ์สมาชิก',
  个人收入记录: 'บันทึกรายได้ส่วนบุคคล',
  机器人采购: 'การจัดซื้อหุ่นยนต์',
  机器人启动押金: 'ค่าเปิดใช้หุ่นยนต์',
  机器人礼物: 'ของขวัญหุ่นยนต์',
  机器人订单: 'คำสั่งหุ่นยนต์',
  机器人: 'หุ่นยนต์',
  一次性总和: 'เงินก้อน',
  总计: 'ทั้งหมด',
  我的机器人: 'หุ่นยนต์ของฉัน',
  被激活: 'เปิดใช้งานแล้ว',
  工作中: 'กำลังทำงาน',
  暂停: 'หยุด',
  结束: 'สิ้นสุด',
  刷新: 'รีเฟรช',
  来源: 'จาก',
  价格: 'ราคา',
  启动押金: 'ราคาเริ่มต้น',
  剩余时间: 'เวลาที่เหลืออยู่',
  激活时间: 'เวลาเปิดใช้งาน',
  号: 'ไม่.',
  我的资产: 'สินทรัพย์ของฉัน',
  退出: 'ออกจากระบบ',
  邀请码: 'รหัสการเชิญ',
  我的钱包余额: 'ยอดคงเหลือในกระเป๋าเงินของฉัน',
  我的机器人仓库: 'โกดังหุ่นยนต์ของฉัน',
  代理信息: 'ข้อมูลตัวแทน',
  去提款: 'การถอนเงิน',
  昨天代理返利: 'เงินคืนเอเจนซี่เมื่อวาน',
  累计代理返利: 'เงินคืนตัวแทนสะสม',
  今天机器人收益: 'รายได้วันนี้',
  累计机器人收益: 'รายได้สะสม',
  累计机器人回扣: 'เงินคืนสะสม',
  累计总回报: 'ผลตอบแทนรวมสะสม',
  自动订单机器人数量: 'จำนวนหุ่นยนต์สั่งอัตโนมัติ',
  我的付款方式: 'วิธีการชำระเงินของฉัน',
  个人收入记录: 'บันทึกรายได้ส่วนบุคคล',
  团队收入记录: 'บันทึกรายได้ของทีม',
  充值记录: 'บันทึกการเติมเงิน',
  我的团队成员: 'สมาชิกในทีมของฉัน',
  活动中心: 'ศูนย์จัดงาน',
  修改登录密码: 'แก้ไขรหัสผ่านเข้าสู่ระบบ',
  交易密码管理: 'การจัดการรหัสผ่านการทำธุรกรรม',
  机器人: 'หุ่นยนต์',
  机器人性能: 'ผลงาน',
  机器人价格: 'ราคาหุ่นยนต์',
 
  有效期: 'ระยะเวลาที่ใช้ได้',
  
  天: 'วัน',
  去购买: 'ที่จะซื้อ',
  买个机器人: 'ซื้อหุ่นยนต์',
 
  预计日收益: 'รายได้รายวันโดยประมาณ',
  启动保证金: 'เริ่มต้นการฝากเงิน',
  请输入购买数量: 'กรุณาระบุจำนวนการสั่งซื้อ',
  个机器人: ' พีซี / หุ่นยนต์',
  机器人购买后需要激活才能工作: 'ต้องเปิดใช้งานหุ่นยนต์เพื่อทำงานหลังจากซื้อ',
  机器人激活需要从余额中扣除相应的启动押金:
    'การเปิดใช้งานหุ่นยนต์จำเป็นต้องหักเงินฝากเริ่มต้นที่เกี่ยวข้องออกจากยอดคงเหลือ',
  机器人暂停机器人停止时将返还启动押金:
    'เมื่อหุ่นยนต์หยุด เงินฝากเริ่มต้นจะถูกส่งคืน',
	不激活的机器人可以赠送:"หุ่นยนต์ที่ไม่ใช้งานสามารถมอบให้ได้",
  购买: 'ซื้อ',
  点击去了解我们: 'คลิกเพื่อเรียนรู้เกี่ยวกับเรา',
  购买机器人: 'ซื้อหุ่นยนต์',
  快速入口: 'เข้าเร็ว',
  '日/个人受益': 'รายวัน / ผลประโยชน์ส่วนตัว',
  购买教程: 'ซื้อบทช่วยสอน',
  订单数量: 'ปริมาณการสั่งซื้อ',
  我们的合作伙伴: 'พันธมิตรของเรา',
  提款: 'การถอนเงิน',
  提款金额: 'จำนวนเงินที่ถอน ',
  你还没有添加银行卡: 'คุณยังไม่ได้เพิ่มบัตรธนาคาร',
  提现说明: 'คำอธิบายการถอน',
  确认提款: 'ยืนยันการถอน ',
  钱包余额: 'ยอดคงเหลือในกระเป๋าเงิน',
  输入数量:"ใส่จำนวนเงิน USDT",
  数量:"จำนวน",
  登录: 'เข้าสู่ระบบ',
  输入账号: 'ใส่เลขที่บัญชี',
  输入密码: 'ใส่รหัสผ่าน',
  还没有账号点击注册: 'ไม่มีบัญชี? คลิกลงทะเบียน',
  忘记密码: 'ลืมรหัสผ่าน',
  重新登录: 'เข้าสู่ระบบอีกครั้ง',
  密码找回成功: 'ดึงรหัสผ่านสำเร็จ',
  找回成功请重新登录: 'เรียกข้อมูลสำเร็จ กรุณาเข้าสู่ระบบอีกครั้ง',
  确认: 'ยืนยัน',
  发送验证码: 'ส่งรหัสยืนยัน',
  再次输入密码: 'ใส่รหัสผ่านอีกครั้ง',
  输入验证码: 'ใส่รหัสยืนยัน',
  输入手机号: 'ใส่เบอร์มือถือ',
  注册: 'ลงทะเบียน',
  输入用户名: 'ใส่ชื่อผู้ใช้',
  请输入邀请码: 'กรุณาใส่รหัสเชิญ',
  返回: 'กลับ',
  密码找回失败: 'การเรียกรหัสผ่านล้มเหลว',
  输入的信息有误: 'กรอกข้อมูลไม่ถูกต้อง',
	
	获取验证码:"รับรหัส",
	手机号码不能为空:"ต้องระบุเบอร์มือถือ",
	账号不存在:"บัญชีไม่มีอยู่",
	发送成功:"ส่งเรียบร้อย",
	今日收益:"รายได้วันนี้",
	
	日收益:"รายได้รายวัน",
	
	数量不能为空:"จำนวนไม่สามารถเว้นว่างได้",
	加载中:"กำลังโหลด",
	唯一码:"รหัสเฉพาะ",
	未激活:"ไม่ทำงาน",
	激活:"การเปิดใช้งาน",
	购买时间:"ซื้อเวลา",
	"钱包余额不足，激活该机器人失败":"ยอดเงินในกระเป๋าไม่เพียงพอ ไม่สามารถเปิดใช้งานหุ่นยนต์ได้",
	暂无数据:"ไม่มีข้อมูล...",
	激活时间:"เวลาเปิดใช้งาน",
	运行时长:"รันไทม์",
	签约购买:"สัญญาซื้อ",
	系统赠送:"ของขวัญจากระบบ",
	状态:"รัฐ",
	正常:"ปกติ",
	我的机器人:"หุ่นยนต์ของฉัน",
	一级会员:"ระดับ 1",
	二级会员:"ระดับ 2",
	三级会员:"ระดับ 3",
	人:"คน",
	充值客服:"เติมเงินบริการลูกค้า",
	充值:"เติมเงิน",
	提现:"การถอนเงิน",
	设置交易密码:"ตั้งรหัสผ่านการทำธุรกรรม",
	登录密码:"รหัสผ่านในการเข้าสู่ระบบ",
	请输入交易密码:"กรุณาใส่รหัสผ่านการทำธุรกรรม",
	再次请输入交易密码:"กรุณาใส่รหัสผ่านการทำธุรกรรมของคุณอีกครั้ง",
	确认: 'ยืนยัน',
	手机号码不能为空:"ต้องระบุเบอร์มือถือ",
	两次密码不一致:"รหัสผ่านทั้งสองไม่สอดคล้องกัน",
	请输入验证码:"กรุณากรอกรหัสยืนยัน",
	操作成功:"ดำเนินการสำเร็จ",
	"用户名或密码不正确,登录失败":"ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง เข้าสู่ระบบล้มเหลว",
	登录成功:"เข้าสู่ระบบสำเร็จ",
	充值说明:"คำแนะนำในการเติมเงิน",
	请先设置支付密码:"กรุณาตั้งรหัสผ่านการชำระเงินก่อน",
	复制成功:"คัดลอกสำเร็จ",
	冻结机器人做单本金:"แช่แข็งคำสั่งของหุ่นยนต์",
	待审核:"ที่จะได้รับการตรวจสอบ",
	成功:"ความสำเร็จ",
	失败:"สอบตก",
	审核中:"อยู่ระหว่างการตรวจสอบ",
	在线充值:"เติมเงินออนไลน์",
	描叙:"บรรยาย",
	银行卡提现:"ถอนบัตรธนาคาร",
	USDT提现:"การถอน USDT",
	三级代理:"ตัวแทนระดับสาม",
	一级代理:"ตัวแทนระดับแรก",
	二级代理:"ตัวแทนรอง",
	做单:"สั่งซื้อสินค้า",
	团队返佣: "ค่าคอมมิชชั่นของทีม",
	购买机器人返佣:"ค่าคอมมิชชั่นการซื้อ",
	本金返还:"ผลตอบแทนของเงินต้น",
	时间:"เวลา",
	机器人做单返还:"หุ่นยนต์สั่งและคืนเงิน",
	涨幅:"เปลี่ยน",
	
  付款方式:"วิธีการชำระเงิน",
	我的留言:"ข้อความของฉัน",
  
  银行卡:'บัตรเครดิตธนาคาร',
  修改: 'แก้ไขใหม่',
 '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'ตรวจสอบให้แน่ใจว่าที่อยู่กระเป๋าเงินถูกต้อง เพื่อป้องกันการสูญเสียที่เกิดจากผู้ใช้ที่ไม่สามารถรับเงินได้เนื่องจากการป้อนที่อยู่ผิด',
 连接借记卡:"เชื่อมต่อบัตรเดบิต",
 银行名称:'ชื่อธนาคาร',
 姓名姓氏: 'ชื่อ',
 IBAN号码:'หมายเลขบัตรธนาคาร',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* สำคัญ: ข้อมูลบัตรเดบิตจะต้องเป็นจริงและถูกต้องก่อนทำการถอน.',
 帮助中心:'ศูนย์ช่วยเหลือ',
 会员须知:'หมายเหตุถึงสมาชิก',
 '24小时内使用USDT实时取款（推荐）':'1. ใช้ USDT เพื่อถอนเงินแบบเรียลไทม์ภายใน 24 ชั่วโมง (แนะนำ)',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2. การถอนด้วยบัตรธนาคาร *เวลาถอนคือ 10:00-18:00 *ฝากเงินภายใน 2 ชั่วโมงหลังจากเริ่มต้นการถอนเงิน *สำหรับเวลาที่ไม่ใช่การถอนหรือการถอนระหว่างธนาคารจะมาถึงเวลา 10:00-18:00 น. ของวันถัดไป',
 我知道了:'ฉันเข้าใจแล้ว!',
 链接点击:'ลิ้งค์คลิก',
 
 建立自己的团队邀请:'สร้างคำเชิญทีมของคุณเอง',

 加入:'ร่วม',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "หุ่นยนต์ที่คุณขายได้รับการยอมรับจากอีกฝ่าย รายได้จากการขายได้ชำระเข้าบัญชีของคุณแล้ว โปรดตรวจสอบ.",
 	"你出售的机器人对方已拒绝接受":"หุ่นยนต์ที่คุณขายถูกอีกฝ่ายปฏิเสธ",
 	向你赠送一台机器人:"หุ่นยนต์จะถูกนำเสนอให้คุณเพื่อยืนยัน",
 	向你出售一台机器人:"ขายหุ่นยนต์ให้คุณรอการยืนยัน",
 	出售价格不能超过机器人价格:"ราคาขายต้องไม่เกินราคาหุ่นยนต์",
 	银行卡已被绑定:"บัตรธนาคารถูกผูกไว้",
 	USDT_OMNI提现:'USDT_OMNI ถอนออก',
 	USDT_ERC20提现:'USDT_ERC20 ถอนออก',
 	银行卡提现:'ถอนบัตรธนาคาร',
 	邀请赠送:'ของขวัญเชิญ',
 	机器人返佣:'คณะกรรมาธิการหุ่นยนต์',
 	升级:'อัพเกรด',
 	充值主网:'เติมเงินเครือข่ายหลัก',
   "您被限制出售机器人,请联系客服了解详情":
     "คุณถูกจำกัดไม่ให้ขายหุ่นยนต์ กรุณาติดต่อฝ่ายบริการลูกค้าสำหรับรายละเอียด",
   交易编号: "หมายเลขธุรกรรม",
   消息详情:"รายละเอียดข้อความ",
   个人收益累计:"รายได้ส่วนบุคคลสะสม",
   今日代理收益:"รายได้ตัวแทนวันนี้",
   代理累计收益:"รายได้สะสมจากหน่วยงาน",
  
   截图保存推荐给朋友:"บันทึกภาพหน้าจอและแนะนำให้เพื่อน",
   复制:"สำเนา",
   
   请输入正确的充值金额:"กรุณากรอกจำนวนเงินที่เติมให้ถูกต้อง",
   推荐:"แนะนำ",
   充值金额:"จำนวนเติมเงิน",
   请上传付款截图:"โปรดอัปโหลดภาพหน้าจอการชำระเงิน",
   充值ID提示:"แจ้ง ID เติมเงิน",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"โปรดตรวจสอบให้แน่ใจว่าที่อยู่กระเป๋าเงินถูกต้อง หากกรอกที่อยู่กระเป๋าเงินไม่ถูกต้อง ผู้ใช้จะรับภาระความสูญเสียที่เกิดจากการไม่สามารถเก็บเงินได้.",
	上传凭证:"อัพโหลดเวาเชอร์",
	不能为空:"ว่างเปล่าไม่ได้",
  绑定钱包地址:'ผูกที่อยู่กระเป๋าสตางค์',
	
	备注:"หมายเหตุ",
	请输入你的名字:"กรุณากรอกชื่อของคุณ",
	请输入银行卡号:"กรุณากรอกหมายเลขบัตรธนาคาร",
	银行卡号:"หมายเลขบัตรธนาคาร",
	添加银行卡:"เพิ่มบัตรธนาคาร",
	请选择银行:"กรุณาเลือกธนาคาร",
	请输入钱包地址:"กรุณากรอกที่อยู่กระเป๋าเงิน",
	钱包地址:"ที่อยู่กระเป๋าเงิน",
	"取款须知":"คำแนะนำในการถอนเงิน",
	"须知1":"1. ถอนเงินแบบเรียลไทม์ด้วย usdt ภายใน 24 ชั่วโมง (แนะนำ)",
	"须知2":"2.บัตรเครดิตธนาคาร",
	"须知3":"การถอนเงิน * เวลาถอนคือ 10:00-18:00",
	"须知4":"*แอปพลิเคชันสำหรับเวลาไม่ถอนหรือถอนระหว่างธนาคารจะได้รับเวลา 10.00 - 18.00 น. ของวันถัดไป",
	
	已绑定:"ผูกพัน",
	去设置:"การตั้งค่า",
	汇率:"อัตราแลกเปลี่ยน",
	请输入正确的提现金额:"กรุณากรอกจำนวนการถอนที่ถูกต้อง",
	提款记录:"บันทึกการถอน",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"เคล็ดลับที่ 1: ที่อยู่กระเป๋าเงินที่ถูกผูกไว้จะต้องสอดคล้องกับกระเป๋าเงินสำหรับชำระเงิน มิฉะนั้นจะไม่ได้รับ。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"เคล็ดลับ 2: คาดว่าจะถึงในประมาณหนึ่งนาทีหลังจากการชาร์จสำเร็จ โปรดตรวจสอบกระเป๋าเงินของคุณ.",
	请先绑定钱包地址:"กรุณาผูกที่อยู่กระเป๋าเงินก่อน",
	输入邮箱:"กรอกอีเมล",
	指定用户不存在:"ไม่มีผู้ใช้ที่ระบุ",
	今日涨幅:"เปลี่ยน",
	快捷充币:"ฝากเงินด่วน",
	快速买USDT:"ซื้อด่วน USDT",
	在线支付:"ชำระเงินออนไลน์",
	自动到账:"ใบเสร็จรับเงินอัตโนมัติ",
	线下支付:"การชำระเงินออฟไลน์",
	联系客服获取验证码:"ติดต่อฝ่ายบริการลูกค้าเพื่อขอรหัสยืนยัน",
	获取:"รับ",
	重新登录:"เข้าสู่ระบบอีกครั้ง",
	退出成功:"ออกได้สำเร็จ",
	不能低于最小充值:"ไม่สามารถต่ำกว่าเติมเงินขั้นต่ำได้",
	不能低于最小提现金额:"ไม่น้อยกว่าจำนวนเงินถอนขั้นต่ำ",
	最小提现:"ถอนขั้นต่ำ",
	官方充币:"การชาร์จอย่างเป็นทางการ",
	银行卡充值:"เติมเงินด้วยบัตรธนาคาร",
	等待审核:"อยู่ระหว่างการพิจารณา",
	可提现金额不足:"จำนวนเงินที่ถอนไม่เพียงพอ",
	未经授权:"ไม่ได้รับการรับรอง",
	交易密码不正确:"รหัสผ่านธุรกรรมไม่ถูกต้อง",
	提现次数不足:"เวลาถอนไม่เพียงพอ",
	需要更新的银行卡不存在:"ไม่มีบัตรธนาคารที่จะอัปเดต",
	银行卡提现:"ถอนบัตรธนาคาร",
	激活金额:"จำนวนการเปิดใช้งาน",
	'赠送/出售':"ให้ / ขาย",
	赠送:"ให้",
	出售:"ขาย",
	账户usdt不足:"ขาดความสมดุล",
	请输入提现金额:"กรุณากรอกจำนวนเงินที่ถอน",
	恭喜新用户:"ขอแสดงความยินดีกับผู้ใช้ใหม่ ",
	kefu1:"วีดีโอสอนยูทูบ",
	登录密码:"รหัสผ่านในการเข้าสู่ระบบ",
	再次请输入交易密码:"ป้อนรหัสผ่านการทำธุรกรรมอีกครั้ง",
	输入手机号码:"หมายเลขโทรศัพท์",
	输入手机号码:"ใส่หมายเลขโทรศัพท์",
	输入密码:"รหัสผ่าน",
	请输入密码:"ใส่รหัสผ่าน",
	手机号格式错误:"ข้อผิดพลาดรูปแบบหมายเลขโทรศัพท์มือถือ",
	"密码错误次数过多,请等待x秒后再试":"รหัสผ่านผิดพลาดมากเกินไป โปรดรอ 600 วินาทีแล้วลองอีกครั้ง",
	"此账号已冻结,请联系客服":"บัญชีนี้ถูกระงับ โปรดติดต่อฝ่ายบริการลูกค้า",
	登录失败:"เข้าสู่ระบบล้มเหลว",
	请勿重复操作:"ห้ามซ้ำ",
	邀请码不正确:"รหัสเชิญไม่ถูกต้อง",
	此账号已冻结:"บัญชีนี้ถูกระงับ",
	请输入手机号:"กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ",
	验证码错误:"รหัสยืนยันผิดพลาด",
	手机号存已存在:"มีเบอร์มือถืออยู่แล้ว",
	注册账号已存在:"บัญชีที่ลงทะเบียนมีอยู่แล้ว",
	请确定新密码:"กรุณายืนยันรหัสผ่านใหม่",
	请再次输入登录密码:"กรุณาใส่รหัสผ่านเข้าสู่ระบบของคุณอีกครั้ง",
	密码长度不能少于6位:"ความยาวรหัสผ่านต้องไม่ต่ำกว่า 6 หลัก",
	
	输入邮箱:"กรอกอีเมล",
	注册邮箱已存在:"มีอีเมลลงทะเบียนอยู่แล้ว",
	请输入用户ID:"ป้อน GMAIL . ของผู้รับ",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":
	"โปรดตรวจสอบข้อมูลอย่างละเอียดหากคุณป้อนชื่อบัญชีของอีกฝ่ายหนึ่งและโอนทรัพย์สิน หากมีข้อผิดพลาดในการดำเนินการ จะไม่สามารถเรียกข้อมูลผ่านระบบหรือฝ่ายบริการลูกค้าของแพลตฟอร์มได้.",
	请输入出售金额:"กรุณาระบุยอดจำหน่าย",
	出售中:"ส่งของขวัญแล้วรอการยืนยัน",
	 收到一台系统赠送的机器人 :"รับหุ่นยนต์จากระบบ",
	 会员出售:"สมาชิกขาย",
	 "出售待确认中,请勿重复操作":"อยู่ระหว่างการยืนยันการขาย ห้ามดำเนินการซ้ำ",
	 激活成功:"เปิดใช้งานความสำเร็จ",
	 操作成功:"ดำเนินการสำเร็จ",
	
	输入邮箱:"กรอกอีเมล",
	
	请输入登录密码:"กรุณาใส่รหัสผ่านเข้าสู่ระบบของคุณ",
	未满足条件:"ไม่ตรงตามเงื่อนไข",
	活动已过期:"กิจกรรมหมดอายุ",
	个人充值奖励活动:"A: Personal deposit bonus event",
	"邀请好友注册，领取佣金":"ชวนเพื่อนลงทะเบียนรับคอมมิชชั่น",
	领取:"ที่จะได้รับ",
	团队规则:"กฎของทีม",
	邮箱已存在:"อีเมลนี้มีอยู่แล้ว",
	获取邮箱验证码:"รับรหัส",
	请输入邮箱:"กรุณากรอกอีเมล์",
	手机号码:"เบอร์โทร",
	邮箱不能为空:"อีเมลต้องไม่เว้นว่าง",
	"出售/赠送":"ขาย / ของขวัญ",
	已接受:"ได้รับการยอมรับ",
	"出售/赠送人":"ผู้ขาย / ผู้ให้",
	"向你赠送一台机器人，待确认":"หุ่นยนต์จะถูกนำเสนอให้คุณเพื่อยืนยัน",
	国码:"รหัสประเทศ",
	取消:"ยกเลิก",
	银行卡号位数错误:"หมายเลขบัตรธนาคารไม่ถูกต้อง",
	官网:"เว็บไซต์อย่างเป็นทางการ",
	注册成功:"เข้าสู่ระบบสำเร็จ",
	设置成功:"ตั้งค่าสำเร็จ",
	手机号码已存在:"มีเบอร์มือถืออยู่แล้ว",
	修改成功:"แก้ไขเรียบร้อยแล้ว",
	立即登录:"มีบัญชีอยู่แล้ว คลิกเพื่อเข้าสู่ระบบ",
	客服:"แชท",
	邮箱:"อีเมล",
	体验机器人不能赠送:"หุ่นยนต์ประสบการณ์ไม่สามารถมอบให้ได้",
	接受:"ยอมรับ",
	拒绝:"ปฏิเสธ",
}
