<script setup>
import { createApp } from 'vue'
import { Grid, GridItem } from 'vant'
</script>
<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('充值') }}</div>
      <div>
        <!-- <img class="img" src="../../assets/img/添加.png" alt="" /> -->
      </div>
    </div>
    <div class="contnet">
		
      <div class="card">
		  <div class="caput">
		    <span
		      class="btn"
		      @click="btnEvent2(item.value)"
		      :class="selectData2 == item.value ? 'check' : ''"
		      v-for="item in paytype"
		      :key="item.value"
		      >{{ item.label }}</span
		    >
		    <!-- <span class="btn" @click="btnEvent">ERC20</span>
		    <span class="btn" @click="btnEvent">ERC20</span> -->
		  </div>
        <div class="exif">
          <p>
            <span class="residual"> {{ info.usdt }}</span>
            <span class="suffix">USDT</span>
          </p>
          <!--<p>
            <span class="suffix"></span> <span class="suffix"><span style="font-size: 12px;">Mex$</span></span>
            <span class="cny">{{ (info.usdt * huilv).toFixed(4) }}</span>
          </p>-->
        </div>
        <div class="exif note">
          <p class="text">{{ $t('钱包余额') }}</p>
          <p class="text">{{ $t('汇率') }}</p>
        <!--</div>
        <div class="exif">
          <p class="tl"> Rp:{{money*huilv1}}  </p >
		<!-- 印尼 IDR -->
          <!--</div>
		<div class="exif">
          <p class="tl"> ฿:{{money*huilv2}} </p>
		 <!-- 泰铢 THB -->
          <!--<p class="tl">
            1 USDT <span class="suffix">≈</span> <span class="suffix"><span style="font-size: 12px;">1 USD</span></span
            >
          </p>-->
        </div>
		<div class="exif">
		  <!--<p class="tl"> MXN:{{money*huilv3}} </p>-->
		 <!-- 泰铢 THB -->
		  <p class="tl">
		    1 USDT <span class="suffix">≈</span> <span class="suffix"><span style="font-size: 12px;">1 USD</span></span
		    >
		  </p>
		</div>	
        <div class="caput">
          <van-grid :column-num="3">
            <span
              class="btn"
              @click="btnEvent(item.value)"
              :class="selectData == item.value ? 'check' : ''"
              v-for="item in list"
              :key="item.value"
            >
              {{ item.label }}
            </span>
            <!-- <span class="btn" @click="btnEvent">ERC20</span>
		  <span class="btn" @click="btnEvent">ERC20</span> -->
          </van-grid>
        </div>

        <van-field
          class="input"
          type="number"
          maxlength="10"
          v-model="money"
          :placeholder="shurutext"
        />
        <div class="description">
          <p class="title">{{ $t('充值说明') }}</p>
          <div class="cont">1 USDT=1 USD</div>
          <div class="cont" v-html="info.cz_tip1"></div>
          <div class="cont" v-html="info.cz_tip2"></div>
		  <div class="cont" v-html="info.cz_tip3"></div>
		  <div class="cont" v-html="info.cz_tip4"></div>
		  <div class="cont" v-html="info.cz_tip5"></div>
          <p></p>
        </div>
      </div>
    </div>
    <div class="base" >
      
<!--    <div class="add ml32 mr32 mt20 flex_row_space-between " @click="select(2)" v-if="info.auto_usdt == 1 && selectData2 == 1">
        <div style="display: flex">
          <div class="imgs">
            <img class="img" src="../../assets/img/usdt2.png" alt="" />
          </div>

          <div class="ml30 te" style="padding-top: 10px">
            <div class="sz">USDT(OMNI)</div>
            <div>{{ $t('自动到账') }}</div>
          </div>
        </div>

        <div class="">
          <van-checkbox v-model="checked2"></van-checkbox>
        </div>
      </div> -->
	  
	<!--  <div class="line" ></div>
	  <div class="add ml32 mr32 mt20 flex_row_space-between" @click="select(4)" style="height: 50px;">
	    <div style="display: flex">
	      <div class="imgs">
	        <img
	          class="img"
	          src="../../assets/img/bank.png"
	          alt=""
	          style="height: 30px;"
	        />
	      </div>
	  
	      <div class="ml30 te">
	        <div class="sz">THAI BANK PAYMENT</div>
	        <div>{{ $t('在线支付') }}</div>
	      </div>
	    </div>
	  
	    <div class="">
	      <van-checkbox v-model="checked4"></van-checkbox>
	    </div>
	  </div> -->
      
     
    </div>
<!-- 	<div class="line" v-if="info.is_xx_bank == 1 && selectData2 == 2"></div>
	<div class="add ml32 mr32 mt20 flex_row_space-between" @click="select(1)" v-if="selectData2 == 2">
	  <div style="display: flex">
	    <div class="imgs">
	      <img
	    class="img"
	    src="../../assets/img/bank01.png"
	    alt=""
	    style="height: 30px;"
	    />
	    </div>
	
	    <div class="ml30 te" style="padding-top: 10px">
	      <div class="sz">INDONESIAN PAYMENT</div>
	      <div>{{ $t('在线支付') }}</div>
	    </div>
	  </div>
	
	  <div class="">
	    <van-checkbox v-model="checked"></van-checkbox>
	  </div>
	</div> -->
	<div class="line" v-if="selectData2 == 1"></div>
	<div class="add ml32 mr32 mt20 mb20 flex_row_space-between" @click="select(1)" style="height: 50px;" v-if="selectData2 == 1">
	  <div style="display: flex">
	    <div class="imgs">
	      <img
	        class="img"
	        src="../../assets/img/usdt1.png"
	        alt=""
	      />
	    </div>
	    <div class="ml30 te">
	      <div class="sz" style="line-height: 30px;">ERC20</div>
	      <div>{{ $t('在线支付') }}</div>
	    </div>
	  </div>
	
	  <div class="">
	    <van-checkbox v-model="checked1"></van-checkbox>
	  </div>
	</div>
	
	<div class="line" ></div>
	<div class="add ml32 mr32 mt20 mb20 flex_row_space-between" @click="select(2)" style="height: 50px;" >
	  <div style="display: flex">
	    <div class="imgs">
	      <img
	        class="img"
	        src="../../assets/img/usdt2.png"
	        alt=""
	      />
	    </div>
	
	    <div class="ml30 te">
	      <div class="sz" style="line-height: 30px;">OMNI</div>
	      <div>{{ $t('在线支付') }}</div>
	    </div>
	  </div>
	
	  <div class="">
	    <van-checkbox v-model="checked2"></van-checkbox>
	  </div>
	<!--</div>
	<div class="line" ></div>
	<div class="add ml32 mr32 mt20 flex_row_space-between" @click="select(3)" style="height: 50px;">
	  <div style="display: flex">
	    <div class="imgs">
	      <img
	        class="img"
	        src="../../assets/img/bank.png"
	        alt=""
	        style="height: 30px;"
	      />
	    </div>
	
	    <div class="ml30 te">
	      <div class="sz">Thai Bank Payment</div>
	      <div>{{ $t('在线支付') }}</div>
	    </div>
	  </div>
	
	  <div class="">
	    <van-checkbox v-model="checked3"></van-checkbox>
	  </div>
	</div>
	<div class="line" ></div>
	<div class="add ml32 mr32 mt20 flex_row_space-between" @click="select(4)" style="height: 50px;">
	  <div style="display: flex">
	    <div class="imgs">
	      <img
	        class="img"
	        src="../../assets/img/bank01.png"
	        alt=""
	        style="height: 30px;"
	      />
	    </div>
	
	    <div class="ml30 te">
	      <div class="sz">Indonesian Payment</div>
	      <div>{{ $t('在线支付') }}</div>
	    </div>
	  </div>
	
	  <div class="">
	    <van-checkbox v-model="checked4"></van-checkbox>
	  </div>-->
	</div>
	<!--<div class="line" ></div>
	<div class="add ml32 mr32 mt20 flex_row_space-between" @click="select(5)" style="height: 50px;">
	  <div style="display: flex">
	    <div class="imgs">
	      <img
	        class="img"
	        src="../../assets/img/bank02.png"
	        alt=""
	        style="height: 30px;"
	      />
	    </div>
	
	    <div class="ml30 te">
	      <div class="sz">Depósito en pesos mexicanos</div>
	      <div>{{ $t('在线支付') }}</div>
	    </div>
	  </div>
	
	  <div class="">
	    <van-checkbox v-model="checked5"></van-checkbox>
	  </div>
	</div>-->
	<!-- <div class="line" ></div>
	<div class="add ml32 mr32 mt20 flex_row_space-between" @click="select(6)" style="height: 50px;">
	  <div style="display: flex">
	    <div class="imgs">
	      <img
	        class="img"
	        src="../../assets/img/bank.png"
	        alt=""
	        style="height: 30px;"
	      />
	    </div>
	
	    <div class="ml30 te">
	      <div class="sz">信用卡THB</div>
	      <div>{{ $t('在线支付') }}</div>
	    </div>
	  </div>
	
	  <div class="">
	    <van-checkbox v-model="checked6"></van-checkbox>
	  </div>
	</div>
	<div class="line"></div> -->
    <!-- <div class="prompt">Cheqam在24小时内取款</div> -->
    <br />
    <br />
	<van-popup
	   v-model="show"
	   round
	   position="bottom"
	   :style="{ height: '80%' }"
	 >
	   <div class="act-form" style="overflow: hidden; border: 0px">
	     <iframe
	       :src="payurl"
	       ref="iframe"
	       style="width: 11.6rem; height: 20rem"
	     ></iframe>
	   </div>
	 </van-popup>
	<van-popup v-model="paypwd" round closeable @close="closepaypwd" class="toastIndex">
		  <div class="wt-select-dialog" style=" height: 5.00097rem;text-align: center;background-color: #2a2c2e">
				  <br>
				  <p style="color: #FFFFFF;text-align: center;z-index: 10; font-size: 18px; ">{{$t("请先设置支付密码")}}</p>
		      <van-row >
				<div >
		        <van-col span="12" style="width: 60%; padding-top: 20px;" @click="gosetting">
		          <van-button  type="info" style="width: 5.5rem;background-color: #2a2c2e" >{{$t("去设置")}}</van-button>
		        </van-col>
				</div>
		      </van-row>
		    </div>
		 
	</van-popup>
	
	<van-popup v-model="tishi" round closeable @close="tishiclose" class="toastIndex">
		  <div class="wt-select-dialog" style=" height: 5.00097rem;text-align: center;background-color: #2a2c2e">
				  <br>
				  <p style="color: #FFFFFF;text-align: center;z-index: 10; font-size: 18px; ">Did you confirm that you already have USDT assets and go to the deposit page?</p>
		      <van-row >
				<div >
		        <van-col span="12" style="width: 60%; padding-top: 20px;" @click="gousdt">
		          <van-button  type="info" style="width: 5.5rem;background-color: #2a2c2e" >{{tishi_text}}</van-button>
		        </van-col>
				</div>
		      </van-row>
		    </div>
		 
	</van-popup>
	
    <HBBTN @clickEvent="confirmEvent">
      {{ $t('确认')}}
    </HBBTN>
    <ROBOT></ROBOT>
	
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import { Dialog } from 'vant';
import HBBTN from '@/components/hb-btn'
import {
  NavBar,
  Field,
  Button,
  RadioGroup,
  Radio,
  CellGroup,
  Cell,
  Toast,
  Popup,
  Row,
  Col
} from 'vant'
import { apigetHuilv, apiUser_mas2, apipayapi, apipayapi2 ,apiUser_bank_mas} from '@/http/api/'

export default {
  name: 'welcome',
  components: {
    HBBTN,
    ROBOT,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Row.name]: Row,
    [Col.name]: Col
  },
  data() {
    return {
      money: '',
      // checked: '1',
      setpaywd: 0,
      suihao: '',
      paypasswd: '',
      huilv: '',
      info: [],
      checked1: true,
      checked2: false,
      checked3: false,
	  checked4: false,
	  checked5: false,
	  checked6: false,
      payurl: '',
      show: false,
      type: 2,
      selectData: '',
      list: [],
	  moneyarr:[],
	  usdttype:[],
	  paypwd:false,
	  tishi_text:"15s",
	  times:15,
	  tishi:false,
	  laina:"",
	  selectData2: 1,
	  huilv1:15100,
	  huilv2:37.5,
	  huilv3:19.5,
	  paytype: [
	    
	    { label: 'Recarga USDT', value: 1 },
		// { label: 'BANK', value: 2 },
	   
	  ],
	  shurutext: this.$t('请输入正确的充值金额')
    }
  },
  watch:{
	  money(val){
		  
		  
	  }
  },
  created() {
	
    apigetHuilv({}).then((res) => {
      this.huilv = res.info
     
    })
    var token = localStorage.getItem('key')
  
    if (!token) {
      this.$router.replace('/login')
    }
    this.token = token
    apiUser_mas2({
      userid: token
    }).then((res) => {
      if (res.status == 10) {
        this.$router.replace('/login')
      }
      this.info = res.user
	  // 判断是否设置交易密码
	  if(res.user.paypassword == ""){
		  this.paypwd = true;
	  }
      //this.suihao = res.user.truename
      var moneylist = res.user.moneylist
      for (let i = 0; i < moneylist.length; i++) {
        var arr = []
        arr.label = moneylist[i]
        // this.selectData = moneylist[i];
        // this.money = moneylist[i];
        arr.value = moneylist[i]
       
		this.moneyarr.push(arr);
		var arr2 = []
		arr2.value = (moneylist[i]*this.huilv).toFixed(0)
		arr2.label = (moneylist[i]*this.huilv).toFixed(0)
		this.usdttype.push(arr2)
		
      }
    })
	
    this.token = token
	// 判断是否需要绑定钱包地址
	apiUser_bank_mas({
	  userid: token
	}).then((res) => {
	  let data = res.user
	  let chexk = true;
	  for (let i = 0; i < data.length; i++) {
	    if (data[i].type != 'usdt_trc20') {
			chexk = false;
	    } 
	  }
	  if(chexk){
		  // Toast.fail(this.$t('请先绑定钱包地址'))
		  // setTimeout(() => {
		
		  //   this.$router.replace('/bindingPurse')
		  			     
		  // }, 1500)
	  }
	 
	})
	console.log(this.list);
	this.btnEvent2(1);
  },
 
  methods: {
	  gosetting(){
		  this.$router.push('/setPwd');
	  },
	  closepaypwd(){
		  this.paypwd = false;
	  },
	  tishiclose(){
		this.tishi = false;  
	  },
    btnEvent(_data) {
      this.selectData = _data
      this.money = _data
    },
	btnEvent2(_data){
		this.selectData2= _data;
		
		this.money = "";
		if(_data == 1){
			
			this.list = this.moneyarr;
			//this.shurutext = "Enter USDT amount"
		}else if(_data == 2){
			//this.shurutext = "Enter NAIRA amount"
			this.list = this.usdttype;		
		}
	},
	verification() {
	     this.tishi = true;
	   
	      var t = setInterval(() => {
			 
	        this.times--; 
			
			this.tishi_text = this.times+"s";
			
			 console.log(this.times)
	        if (this.times <= 0) {
	          this.tishi_text = "OK"
	          clearInterval(t);
	        }
	      }, 1000); //1000毫秒后执行
	},
	gousdt(){
		if(this.times <=0 ){
			this.$router.push('/address?type=' + this.type + '&money=' + this.money)
		}
		
	},
    confirmEvent() {
		
      if (this.money == 0) {
        Toast.fail(this.$t('请输入正确的充值金额'))
        return
      }
      if (this.type == 1 || this.type == 2) {
        if(this.money < 10){
        	  Toast.fail(this.$t('不能低于最小充值'))
        	  return
        }
      } 
      // Min(520000.0) ~ Max(8250000.0)"}
	  if(this.type == 5){
		  if(this.money < 10){
		  	   Toast.fail(this.$t('不能低于最小充值')+"10USDT")
		  	  return
		  }
	  }
	  // Min(1200.0) ~ Max(370000.0)
	  // if(this.type == 6){
		 //  if(this.money*this.huilv1 < 1200){
		 //  	  Toast.fail(this.$t('不能低于最小充值')+"Min(1200.0) ~ Max(370000.0)")
		 //  	  return
		 //  }
	  // }
	  if(this.type == 1){
		  this.$router.push('/payusdt?type=1&money=' + this.money)
		  return
	  }
	  if(this.type == 2){
		  this.$router.push('/payusdt?type=2&money=' + this.money)
	  	  return
	  }
	  if (this.type == 3 || this.type== 4 || this.type == 5 || this.type==6) {
	
	    //this.show = true
	    Toast.loading({
	      duration: 0,
	      message: this.$t('加载中'),
	      forbidClick: true
	    })
	    apipayapi({
	      userid: this.token,
	      money: this.money,
	      sj_money: this.money,
	      tongdao: this.type
	    }).then((res) => {
	      if (res.status == 1) {
	        Toast.clear()
	        console.log(res.url + '&amount=' + this.money* this.huilv2)
			let url = res.url + '&amount=' + this.money;
			if(this.type == 3){
				url = res.url + '&amount=' + this.money * this.huilv2
			}else if(this.type == 4){
				url = res.url + '&amount=' + this.money * this.huilv1
			}else if(this.type == 5){
				url = res.url + '&amount=' + this.money * this.huilv3
			}else if(this.type == 6){
				url = res.url + '&amount=' + this.money 
			}
	        
	        window.location.href = url
	      } else {
	        Toast.fail(this.$t(res.info))
	        return
	      }
	    })
	  }
    },
    getBack() {
      this.$router.back()
    },
    select(index) {
      if (index == 1) {
        this.checked1 = true
        this.checked2 = false
        this.checked3 = false
		this.checked4 = false
		this.checked5 = false
		this.checked6 = false
        this.type = 1
      } else if (index == 2) {
        this.checked1 = false
        this.checked2 = true
        this.checked3 = false
		this.checked4 = false
		this.checked5 = false
		this.checked6 = false
        this.type = 2
      } else if (index == 3) {
        this.checked1 = false
        this.checked2 = false
        this.checked3 = true
		this.checked4 = false
		this.checked5 = false
        this.type = 3
      }else if (index == 4) {
        this.checked1 = false
        this.checked2 = false
        this.checked3 = false
		this.checked4 = true
		this.checked5 = false
		this.checked6 = false
        this.type = 4
      }else if (index == 5) {
        this.checked1 = false
        this.checked2 = false
        this.checked3 = false
		this.checked4 = false
		this.checked5 = true
		this.checked6 = false
        this.type = 5
      }else if (index == 6) {
        this.checked1 = false
        this.checked2 = false
        this.checked3 = false
		this.checked4 = false
		this.checked5 = false
		this.checked6 = true
        this.type = 6
      }
	  
    },
    paysub() {
      // 需要传入实际金额跟 汇率金额
      Toast.loading({
        duration: 0,
        message: this.$t('加载中'),
        forbidClick: true
      })
      apipayapi({
        userid: this.token,
        money: this.money,
        sj_money: this.money,
        tongdao: this.type
      }).then((res) => {
        if (res.status == 1) {
          console.log(res.url + '&amount=' + this.money)
          let url = res.url + '&amount=' + this.money * this.huilv
          window.location.href = url
        } else {
          Toast.fail(this.$t(res.info))
          return
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .contnet {
    margin-top: 42px;
    width: 100%;
    border-radius: 20px;
    background-color: #2a2c2e;
    .card {
      padding: 40px 26px;
      .caput {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        width: 100%;

        .btn {
          text-align: center;
          font-size: 0.2rem;
          height: 0.2rem;
          width: 30%;
          padding: 0.26667rem 0.53333rem;
          border-radius: 0.2rem;
          background-color: #fff;
          margin-left: 0.5rem;
          margin-top: 0.1rem;
        }
        .check {
          background-color: #0d9135;
        }
      }
      .exif {
        display: flex;
        align-items: center;
        padding: 0 30px;
        justify-content: space-between;
        .residual {
          color: #ffffff;
          font-size: 54px;
          margin-right: 10px;
        }
        .suffix {
          color: #888;
          margin-right: 20px;
        }
        .cny {
          color: #fff;
          font-size: 30px;
        }
        .text {
          font-size: 24px;
          color: #888;
        }
        .tl {
          color: #fff;
          font-size: 22px;
        }
      }
      .description {
        .title {
          color: #fff;
          font-size: 30px;
        }
        .cont {
          color: #888;
          margin-bottom: 5px;
          line-height: 40px;
        }
      }
      .note {
        height: 12px;
        margin-top: -18px;
        margin-bottom: 50px;
      }
      ::v-deep input.van-field__control::-webkit-input-placeholder {
        color: #888;
      }
    }
  }
  .base {
    margin-top: 40px;
    .untility {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .img {
        width: 80px;
        height: 80px;
      }
      .tt {
        color: #fff;
        font-size: 32px;
      }
      .explain {
        margin-top: -20px;
        color: #888;
      }
      .before {
        display: flex;
        align-items: center;
      }
    }
  }
  .imgs {
    // margin-right: 70px;
    .img {
      width: 90px;
      height: 90px;
    }
  }
  .prompt {
    width: 100%;
    color: #888;
    margin-top: 90px;
    margin-bottom: 24px;
    text-align: center;
  }
  .robot {
    position: fixed;
    bottom: 190px;
    right: 60px;
    .img {
      width: 90px;
      height: 90px;
    }
  }
  ::v-deep .van-icon-success:before {
    color: #171717;
  }
  .te {
    color: #fff;
    .sz {
      font-size: 30px;
    }
  }
  ::v-deep .van-icon-success:before {
    color: #fff !important;
  }
  ::v-deep.van-checkbox__icon--checked .van-icon {
    // color: #fff !important;
    background-color: #0d9135 !important;
    border-color: #0d9135 !important;
  }
}
</style>
