export default {
  tabbar: {
    首页: 'হোম',
    市场: 'মার্কেট',
    任务: 'কাজ',
    团队: 'টীম',
    我的: 'আমার'
  },
  navbar: {
    充值: 'রিচার্জ',
    提现: 'প্রত্যাহার',
    帮助: 'সাহায্য',
    分享: 'ভাগ করুন',
    活动: 'ঘটনা'
  },	 
  payment: {
    付款方式详情: 'পেমেন্ট পদ্ধতির বিশদ বিবরণ',
    银行卡: 'ব্যাংক কার্ড',
    钱包地址: 'ওয়ালেট ঠিকানা',
    交易密码: 'লেনদেনের পাসওয়ার্ড',
    保存: 'সংরক্ষণ'
  },
  market: {
    市场: 'মার্কেট',
    名称: 'নাম',
    价格: 'মূল্য',
  },
 
  task: {
    任务: 'টাস্ক',
	语言切换: 'ভাষা স্যুইচিং',
    我的余额: 'আমার ভারসাম্য',
    增加订单数量: 'অর্ডারের পরিমাণ বাড়ান',
    今日剩余订单: 'আজ বাকি আদেশ',
    频率: 'ফ্রিকোয়েন্সি',
    手动交易: 'ম্যানুয়াল ট্রেডিং',
    预期收益: 'প্রত্যাশিত আয়',
    需要启动存款: 'আমানত শুরু করতে হবে',
    任务说明: 'টাস্ক বিবরণ',
    每天可以完成10个任务: 'প্রতিদিন 10টি কাজ সম্পূর্ণ করতে পারে',
    联系VIP助手增加任务数量: 'কাজের সংখ্যা বাড়াতে ভিআইপি সহকারীর সাথে যোগাযোগ করুন',
    每个用户的任务总数为300次: 'ব্যবহারকারী প্রতি মোট কাজের সংখ্যা 300',
    购买机器人无需人工操作即可自动交易: 'রোবট কেনার সময় ম্যানুয়াল অপারেশন ছাড়াই স্বয়ংক্রিয় লেনদেন',
    我知道了: 'আমি বুঝতে পেরেছি!'
  },
  团队: 'টীম',
  规则: 'নিয়ম',
  会员级别: 'সদস্য স্তর',
  经验: 'অভিজ্ঞতা',
  代理信息: 'এজেন্ট তথ্য',
  更多: 'আরও',
  团队奖励: 'দল পুরষ্কার',
  昨天: 'গতকাল',
  本星期: 'এই সপ্তাহ',
  全部: 'সব',
  团队收入记录: 'দলের আয়ের রেকর্ড',
  机器人回扣: 'রোবট রিবেট',
  会员体验: 'সদস্য অভিজ্ঞতা',
  个人收入记录: 'ব্যক্তিগত আয়ের রেকর্ড',
  机器人采购: 'রোবট ক্রয়',
  机器人启动押金: 'রোবট স্টার্টআপ আমানত',
  机器人礼物: 'রোবট উপহার',
  机器人订单: 'রোবট অর্ডার',
  机器人: 'রোবট',
  一次性总和: 'একক সমষ্টি',
  总计: 'মোট',
  我的机器人: 'আমার রোবট',
  被激活: 'সক্রিয়',
  工作中: 'কাজ করছে',
  暂停: 'থামো',
  结束: 'শেষ',
  刷新: 'রিফ্রেশ',
  来源: 'থেকে',
  价格: 'মূল্য',
  启动押金: 'দাম শুরু করুন',
  剩余时间: 'অবশিষ্ট সময়',
  激活时间: 'সক্রিয়করণ সময়',
  号: 'না.',
  我的资产: 'আমার সম্পদ',
  退出: 'লগ আউট করুন',
  邀请码: 'আমন্ত্রিত কোড',
  我的钱包余额: 'আমার ওয়ালেট ব্যালেন্স',
  我的机器人仓库: 'আমার রোবট গুদাম',
  代理信息: 'এজেন্ট তথ্য',
  去提款: 'প্রত্যাহার',
  昨天代理返利: 'এজেন্সি রিবেট গতকাল',
  累计代理返利: 'সঞ্চিত সংস্থা রিবেট',
  今天机器人收益: 'আজ রাজস্ব',
  累计机器人收益: 'পুঞ্জীভূত আয়',
  累计机器人回扣: 'পুঞ্জীভূত রিবেট',
  累计总回报: 'ক্রমবর্ধমান মোট রিটার্ন',
  自动订单机器人数量: 'স্বয়ংক্রিয় অর্ডার রোবট সংখ্যা',
  我的付款方式: 'আমার পেমেন্ট পদ্ধতি',
  个人收入记录: 'ব্যক্তিগত আয়ের রেকর্ড',
  团队收入记录: 'দলের আয়ের রেকর্ড',
  充值记录: 'রিচার্জ রেকর্ড',
  我的团队成员: 'আমার দলের সদস্যরা',
  活动中心: 'ইভেন্ট সেন্টার',
  修改登录密码: 'লগইন পাসওয়ার্ড পরিবর্তন করুন',
  交易密码管理: 'লেনদেন পাসওয়ার্ড পরিচালনা',
  机器人: 'রোবট',
  机器人性能: 'রোবট কর্মক্ষমতা',
  机器人价格: 'রোবট মূল্য',
 
  有效期: 'বৈধতার সময়কাল',
  
  天: 'দিন',
  去购买: 'কেনার জন্য',
  买个机器人: 'রোবট কিনুন',
 
  预计日收益: 'আনুমানিক দৈনিক আয়',
  启动保证金: 'স্টার্ট আপ ডিপোজিট',
  请输入购买数量: 'ক্রয় পরিমাণ লিখুন দয়া করে',
  个机器人: ' PCs / রোবট',
  机器人购买后需要激活才能工作: 'কেনার পর কাজ করার জন্য রোবটটিকে সক্রিয় করতে হবে',
  机器人激活需要从余额中扣除相应的启动押金:
    'রোবট অ্যাক্টিভেশনের জন্য ব্যালেন্স থেকে সংশ্লিষ্ট স্টার্টআপ ডিপোজিট কাটতে হবে',
  机器人暂停机器人停止时将返还启动押金:
    'রোবট বন্ধ হয়ে গেলে, স্টার্টআপ আমানত ফেরত দেওয়া হবে',
	不激活的机器人可以赠送:"নিষ্ক্রিয় রোবট দূরে দেওয়া যেতে পারে",
  购买: 'কিনুন',
  点击去了解我们: 'আমাদের সম্পর্কে জানতে ক্লিক করুন',
  购买机器人: 'রোবট কিনুন',
  快速入口: 'দ্রুত এন্ট্রি',
  '日/个人受益': 'দৈনিক/ব্যক্তিগত সুবিধা',
  购买教程: 'টিউটোরিয়াল কিনুন',
  订单数量: 'অর্ডার পরিমাণ',
  我们的合作伙伴: 'আমাদের অংশীদারদের',
  提款: 'প্রত্যাহার',
  提款金额: 'প্রত্যাহারের পরিমাণ ',
  你还没有添加银行卡: 'আপনি একটি ব্যাঙ্ক কার্ড যোগ করেননি',
  提现说明: 'প্রত্যাহারের বর্ণনা',
  确认提款: 'প্রত্যাহার নিশ্চিত করুন ',
  钱包余额: 'ওয়ালেট ব্যালেন্স',
  输入数量:"USDT পরিমাণ লিখুন",
  数量:"পরিমাণ",
  登录: 'লগইন',
  输入账号: 'অ্যাকাউন্ট নম্বর লিখুন',
  输入密码: 'সংকেতশব্দ দিন',
  还没有账号点击注册: 'কোন হিসাব নেই? নিবন্ধন ক্লিক করুন',
  忘记密码: 'পাসওয়ার্ড ভুলে গেছেন',
  重新登录: 'আবার লগ ইন করুন',
  密码找回成功: 'পাসওয়ার্ড সফলভাবে পুনরুদ্ধার করা হয়েছে৷',
  找回成功请重新登录: 'সফলভাবে পুনরুদ্ধার করা হয়েছে, আবার লগ ইন করুন',
  确认: 'কনফার্ম করুন',
  发送验证码: 'যাচাইকরণ কোড পাঠান',
  再次输入密码: 'আবার পাসওয়ার্ড দিন',
  输入验证码: 'যাচাই কোড লিখুন',
  输入手机号: 'মোবাইল নম্বর লিখুন',
  注册: 'রেজিস্টার করুন',
  输入用户名: 'একটি ব্যবহারকারীর নাম লিখুন',
  请输入邀请码: 'অনুগ্রহ করে আমন্ত্রণ কোড লিখুন',
  返回: 'প্রত্যাবর্তন',
  密码找回失败: 'পাসওয়ার্ড পুনরুদ্ধার ব্যর্থ হয়েছে',
  输入的信息有误: 'ভুল তথ্য প্রবেশ করান',
	
	获取验证码:"কোড পেতে",
	手机号码不能为空:"মোবাইল নম্বর খালি রাখা যাবে না",
	账号不存在:"অ্যাকাউন্ট এর অস্তিত্ব নেই",
	发送成功:"সফলভাবে প্রেরিত",
	今日收益:"আজকের আয়",
	
	日收益:"দৈনিক আয়",
	
	数量不能为空:"পরিমাণ খালি হতে পারে না",
	加载中:"লোড হচ্ছে৷",
	唯一码:"অনন্য কোড",
	未激活:"সক্রিয় নয়",
	激活:"সক্রিয়করণ",
	购买时间:"ক্রয় সময়",
	"钱包余额不足，激活该机器人失败":"অপর্যাপ্ত ওয়ালেট ব্যালেন্স, রোবট সক্রিয় করতে ব্যর্থ হয়েছে৷",
	暂无数据:"কোন তথ্য নেই...",
	激活时间:"সক্রিয়করণ সময়",
	运行时长:"রানটাইম",
	签约购买:"চুক্তি কেনাকাটা",
	系统赠送:"সিস্টেম উপহার",
	状态:"রাজ্য",
	正常:"স্বাভাবিক",
	我的机器人:"আমার রোবট",
	一级会员:"স্তর 1",
	二级会员:"স্তর 2",
	三级会员:"স্তর 3",
	人:"মানুষ",
	充值客服:"গ্রাহক পরিষেবা রিচার্জ করুন",
	充值:"রিচার্জ",
	提现:"প্রত্যাহার",
	设置交易密码:"লেনদেনের পাসওয়ার্ড সেট করুন",
	登录密码:"লগইন পাসওয়ার্ড",
	请输入交易密码:"লেনদেনের পাসওয়ার্ড লিখুন",
	再次请输入交易密码:"আপনার লেনদেনের পাসওয়ার্ড আবার লিখুন",
	确认: 'কনফার্ম করুন',
	手机号码不能为空:"মোবাইল নম্বর খালি রাখা যাবে না",
	两次密码不一致:"দুটি পাসওয়ার্ড অসামঞ্জস্যপূর্ণ",
	请输入验证码:"যাচাই কোড লিখুন দয়া করে",
	操作成功:"অপারেশন সফল",
	"用户名或密码不正确,登录失败":"ভুল ব্যবহারকারীর নাম বা পাসওয়ার্ড, লগইন ব্যর্থ হয়েছে",
	登录成功:"সফল লগইন",
	充值说明:"রিচার্জ নির্দেশাবলী",
	请先设置支付密码:"অনুগ্রহ করে প্রথমে পেমেন্ট পাসওয়ার্ড সেট করুন",
	复制成功:"কপি সফল",
	冻结机器人做单本金:"রোবট অর্ডারের প্রিন্সিপ্যাল ফ্রিজিং",
	待审核:"পর্যালোচনা করা",
	成功:"সফলতা",
	失败:"ব্যর্থ",
	审核中:"পর্যালোচনা অধীনে",
	在线充值:"অনলাইন রিচার্জ",
	描叙:"বর্ণনা",
	银行卡提现:"ব্যাংক কার্ড উত্তোলন",
	USDT提现:"USDT প্রত্যাহার",
	三级代理:"তৃতীয় স্তরের এজেন্ট",
	一级代理:"প্রাথমিক এজেন্ট",
	二级代理:"সেকেন্ডারি এজেন্ট",
	做单:"একটি আদেশ করুন",
	团队返佣: "টিম রিবেট",
	购买机器人返佣:"রোবট রিবেট কিনুন",
	本金返还:"প্রিন্সিপালের প্রত্যাবর্তন",
	时间:"টাইম",
	机器人做单返还:"রোবট অর্ডার করে এবং ফেরত দেয়",
	涨幅:"পরিবর্তন",
	
  付款方式:"মূল্যপরিশোধ পদ্ধতি",
	我的留言:"আমার বার্তা",
  
  银行卡:'ব্যাংক কার্ড',
  修改: 'সংশোধন করা',
 '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'মানিব্যাগের ঠিকানা সঠিক কিনা তা নিশ্চিত করুন, যাতে ভুল ঠিকানা ইনপুটের কারণে অর্থ গ্রহণ করতে না পারেন এমন ব্যবহারকারীদের ক্ষতি রোধ করতে',
 连接借记卡:"ডেবিট কার্ড কানেক্ট করুন",
 银行名称:'ব্যাংকের নাম',
 姓名姓氏: 'নাম',
 IBAN号码:'ব্যাঙ্ক কার্ড নং',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* গুরুত্বপূর্ণ: তোলার আগে ডেবিট কার্ডের তথ্য সত্য এবং বৈধ হতে হবে.',
 帮助中心:'সাহায্য কেন্দ্র',
 会员须知:'সদস্যদের নোট',
 '24小时内使用USDT实时取款（推荐）':'1. 24 ঘন্টার মধ্যে usdt দিয়ে রিয়েল টাইম প্রত্যাহার (প্রস্তাবিত)',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2. ব্যাঙ্ক কার্ড উত্তোলন * তোলার সময় হল 10:00-18:00 * প্রত্যাহার শুরু হওয়ার 2 ঘন্টার মধ্যে অ্যাকাউন্ট এন্ট্রি * প্রত্যাহার না করার সময় বা আন্তঃব্যাঙ্ক থেকে তোলার জন্য আবেদন পরের দিন 10:00-18:00 এ গৃহীত হবে',
 我知道了:'আমি বুঝতে পেরেছি!',
 链接点击:'লিঙ্ক ক্লিক করুন',
 
 建立自己的团队邀请:'আপনার নিজস্ব দল আমন্ত্রণ তৈরি করুন',

 加入:'যোগদান',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "আপনি যে রোবটটি বিক্রি করেছেন তা অন্য পক্ষ গ্রহণ করেছে। বিক্রয় আয় আপনার অ্যাকাউন্টে প্রদান করা হয়েছে. চেক করুন.",
 	"你出售的机器人对方已拒绝接受":"আপনি যে রোবটটি বিক্রি করেছেন তা অন্য পক্ষ প্রত্যাখ্যান করেছে",
 	向你赠送一台机器人:"নিশ্চিতকরণের জন্য একটি রোবট আপনার কাছে উপস্থাপন করা হয়েছে",
 	向你出售一台机器人:"নিশ্চিত হওয়ার জন্য আপনাকে একটি রোবট বিক্রি করব",
 	出售价格不能超过机器人价格:"বিক্রির মূল্য রোবটের দামের বেশি হতে পারে না",
 	银行卡已被绑定:"ব্যাংক কার্ড আবদ্ধ করা হয়েছে",
 	USDT_OMNI提现:'USDT_OMNI প্রত্যাহার',
 	USDT_ERC20提现:'USDT ERC20 প্রত্যাহার',
 	银行卡提现:'ব্যাংক কার্ড উত্তোলন',
 	邀请赠送:'আমন্ত্রণ উপহার',
 	机器人返佣:'রোবট কমিশন',
 	升级:'আপগ্রেড',
 	充值主网:'রিচার্জ প্রধান নেটওয়ার্ক',
   "您被限制出售机器人,请联系客服了解详情":
     "আপনি রোবট বিক্রি থেকে সীমাবদ্ধ। বিস্তারিত জানার জন্য গ্রাহক সেবা যোগাযোগ করুন",
   交易编号: "লেনদেন নং",
   消息详情:"বার্তা বিবরণ",
   个人收益累计:"সঞ্চিত ব্যক্তিগত আয়",
   今日代理收益:"এজেন্সি আয় আজ",
   代理累计收益:"এজেন্সি থেকে সংগৃহীত আয়",
  
   截图保存推荐给朋友:"স্ক্রিনশট সংরক্ষণ করুন এবং বন্ধুদের সুপারিশ করুন",
   复制:"অনুলিপি",
   
   请输入正确的充值金额:"সঠিক রিচার্জ পরিমাণ লিখুন",
   推荐:"সুপারিশ",
   充值金额:"রিচার্জ পরিমাণ",
   请上传付款截图:"পেমেন্ট স্ক্রিনশট আপলোড করুন",
   充值ID提示:"রিচার্জ আইডি প্রম্পট",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"মানিব্যাগের ঠিকানা সঠিক কিনা তা নিশ্চিত করুন। মানিব্যাগের ঠিকানা ভুলভাবে পূরণ করা হলে, ব্যবহারকারী অর্থ সংগ্রহ করতে অক্ষমতার কারণে সৃষ্ট ক্ষতি বহন করবে.",
	上传凭证:"ভাউচার আপলোড করুন",
	不能为空:"খালি হতে পারেনা",
  绑定钱包地址:'ওয়ালেট ঠিকানা বাঁধুন',
	
	备注:"মন্তব্য",
	请输入你的名字:"তোমার নাম প্রবেশ করাও",
	请输入银行卡号:"ব্যাঙ্ক কার্ড নম্বর লিখুন",
	银行卡号:"ব্যাঙ্ক কার্ড নং",
	添加银行卡:"ব্যাঙ্ক কার্ড যোগ করুন",
	请选择银行:"একটি ব্যাঙ্ক নির্বাচন করুন",
	请输入钱包地址:"মানিব্যাগ ঠিকানা লিখুন",
	钱包地址:"ওয়ালেট ঠিকানা",
	"取款须知":"প্রত্যাহারের জন্য নির্দেশাবলী",
	"须知1":"1. 24 ঘন্টার মধ্যে usdt দিয়ে রিয়েল টাইম প্রত্যাহার (প্রস্তাবিত)",
	"须知2":"2.ব্যাংক কার্ড",
	"须知3":"প্রত্যাহার * প্রত্যাহারের সময় 10:00-18:00",
	"须知4":"*প্রত্যাহার না করার সময় বা আন্তঃব্যাংক প্রত্যাহারের আবেদন পরের দিন 10:00-18:00 এ গৃহীত হবে",
	
	已绑定:"আবদ্ধ",
	去设置:"স্থাপন",
	汇率:"বিনিময় হার",
	请输入正确的提现金额:"সঠিক প্রত্যাহার পরিমাণ লিখুন দয়া করে",
	提款记录:"প্রত্যাহার রেকর্ড",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"টিপ1: আবদ্ধ ওয়ালেট ঠিকানা অবশ্যই পেমেন্ট ওয়ালেটের সাথে সামঞ্জস্যপূর্ণ হতে হবে, অন্যথায় এটি প্রাপ্ত হবে না。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"টিপ2:সফল রিচার্জের পর এটি প্রায় এক মিনিটের মধ্যে পৌঁছাবে বলে আশা করা হচ্ছে। আপনার মানিব্যাগ চেক করুন.",
	请先绑定钱包地址:"প্রথমে মানিব্যাগ ঠিকানা বাঁধাই করুন",
	输入邮箱:"Gmail লিখুন",
	指定用户不存在:"নির্দিষ্ট ব্যবহারকারীর অস্তিত্ব নেই",
	今日涨幅:"বাড়ান",
	银行:"ব্যাংক",
	快捷充币:"দ্রুত ডিপোজিট",
	快速买USDT:"দ্রুত কিনুন USDT",
	在线支付:"অনলাইন পেমেন্ট",
	自动到账:"স্বয়ংক্রিয় প্রাপ্তি",
	线下支付:"অফলাইন পেমেন্ট",
	联系客服获取验证码:"যাচাইকরণ কোডের জন্য গ্রাহক পরিষেবার সাথে যোগাযোগ করুন",
	获取:"পেতে",
	重新登录:"আবার লগ ইন করুন",
	退出成功:"প্রস্থান সফল",
	不能低于最小充值:"ন্যূনতম রিচার্জের চেয়ে কম হতে পারে না",
	不能低于最小提现金额:"ন্যূনতম উত্তোলনের পরিমাণের চেয়ে কম নয়",
	最小提现:"ন্যূনতম প্রত্যাহার",
	官方充币:"অফিসিয়াল চার্জিং",
	银行卡充值:"ব্যাঙ্ক কার্ড রিচার্জ",
	等待审核:"মুলতুবি পর্যালোচনা",
	可提现金额不足:"অপর্যাপ্ত প্রত্যাহারের পরিমাণ",
	未经授权:"অননুমোদিত",
	交易密码不正确:"ভুল লেনদেনের পাসওয়ার্ড",
	提现次数不足:"অপর্যাপ্ত প্রত্যাহার সময়",
	需要更新的银行卡不存在:"যে ব্যাঙ্ক কার্ডটি আপডেট করা হবে সেটি বিদ্যমান নেই৷",
	银行卡提现:"ব্যাংক কার্ড উত্তোলন",
	激活金额:"সক্রিয়করণ পরিমাণ",
	'赠送/出售':"দিন/বিক্রয় করুন",
	赠送:"দেন",
	出售:"বিক্রয় করুন",
	账户usdt不足:"ভারসাম্যের অভাব",
	请输入提现金额:"প্রত্যাহার পরিমাণ লিখুন দয়া করে",
	恭喜新用户:"নতুন ব্যবহারকারীদের অভিনন্দন ",
	kefu1:"ইউটিউব ভিডিও টিউটোরিয়াল",
	登录密码:"প্রবেশের গুপ্তসংকেত",
	再次请输入交易密码:"আবার লেনদেন পাসওয়ার্ড লিখুন",
	输入手机号码:"ফোন নম্বর",
	输入手机号码:"ফোন নম্বর লিখুন",
	输入密码:"পাসওয়ার্ড",
	请输入密码:"পাসওয়ার্ড লিখুন",
	手机号格式错误:"মোবাইল নম্বর বিন্যাস ত্রুটি",
	"密码错误次数过多,请等待x秒后再试":"অনেক বেশি পাসওয়ার্ড ত্রুটি, অনুগ্রহ করে 600 সেকেন্ড অপেক্ষা করুন এবং আবার চেষ্টা করুন",
	"此账号已冻结,请联系客服":"এই অ্যাকাউন্টটি হিমায়িত করা হয়েছে, অনুগ্রহ করে গ্রাহক পরিষেবার সাথে যোগাযোগ করুন",
	登录失败:"লগইন ব্যর্থ",
	请勿重复操作:"পুনরাবৃত্তি করবেন না",
	邀请码不正确:"ভুল আমন্ত্রণ কোড",
	此账号已冻结:"এই অ্যাকাউন্ট স্থগিত করা হয়েছে",
	请输入手机号:"আপনার মোবাইল নম্বর লিখুন",
	验证码错误:"যাচাইকরণ কোড ত্রুটি",
	手机号存已存在:"মোবাইল ফোন নম্বর আগে থেকেই আছে",
	注册账号已存在:"নিবন্ধিত অ্যাকাউন্ট ইতিমধ্যেই বিদ্যমান",
	请确定新密码:"নতুন পাসওয়ার্ড নিশ্চিত করুন",
	请再次输入登录密码:"আপনার লগইন পাসওয়ার্ড আবার লিখুন",
	密码长度不能少于6位:"পাসওয়ার্ডের দৈর্ঘ্য ৬ সংখ্যার কম হতে পারে না",
	
	输入邮箱:"Gmail এ প্রবেশ করুন",
	注册邮箱已存在:"নিবন্ধন জিমেইল ইতিমধ্যেই বিদ্যমান",
	请输入用户ID:"অনুগ্রহ করে ইউজার আইডি লিখুন",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":
	"আপনি অন্য পক্ষের অ্যাকাউন্টের নাম লিখলে এবং সম্পত্তি হস্তান্তর করলে দয়া করে তথ্যটি সাবধানে পর্যালোচনা করুন। অপারেশন ত্রুটি থাকলে, এটি সিস্টেম বা প্ল্যাটফর্ম গ্রাহক পরিষেবার মাধ্যমে পুনরুদ্ধার করা যাবে না.",
	请输入出售金额:"অনুগ্রহ করে বিক্রয়ের পরিমাণ লিখুন",
	出售中:"উপহার জমা দেওয়া হয়েছে, নিশ্চিতকরণের জন্য অপেক্ষা করছে",
	 收到一台系统赠送的机器人 :"সিস্টেম থেকে একটি রোবট প্রাপ্ত",
	 会员出售:"সদস্যরা বিক্রি করে",
	 "出售待确认中,请勿重复操作":"বিক্রয় মুলতুবি নিশ্চিতকরণ, অপারেশন পুনরাবৃত্তি করবেন না",
	 激活成功:"সাফল্য সক্রিয় করুন",
	 操作成功:"অপারেশন সফল",
	
	输入邮箱:"Gmail এ প্রবেশ করুন",
	
	请输入登录密码:"আপনার লগইন পাসওয়ার্ড লিখুন",
	未满足条件:"শর্ত পূরণ হয়নি",
	活动已过期:"কার্যকলাপের মেয়াদ শেষ",
	个人充值奖励活动:"A: Personal deposit bonus event",
	"邀请好友注册，领取佣金":"রেজিস্টার করতে এবং কমিশন পেতে বন্ধুদের আমন্ত্রণ জানান",
	领取:"গ্রহণ করতে",
	团队规则:"টিমের নিয়ম",
	邮箱已存在:"Gmail ইতিমধ্যেই বিদ্যমান",
	获取邮箱验证码:"কোড পেতে",
	请输入邮箱:"ইমেল ঠিকানা লিখুন",
	手机号码:"ফোন",
	邮箱不能为空:"ইমেল খালি হতে পারে না",
	"出售/赠送":"বিক্রয় / উপহার",
	已接受:"গৃহীত",
	"出售/赠送人":"বিক্রেতা/দাতা",
	"向你赠送一台机器人，待确认":"নিশ্চিতকরণের জন্য একটি রোবট আপনার কাছে উপস্থাপন করা হয়েছে",
	国码:"কান্ট্রি কোড",
	取消:"বাতিল",
	银行卡号位数错误:"ব্যাঙ্ক কার্ডের নম্বর ভুল",
	官网:"অফিসিয়াল সাইট",
	注册成功:"লগইন সফল",
	设置成功:"সফলভাবে সেট",
	手机号码已存在:"মোবাইল নম্বর আগে থেকেই আছে",
	修改成功:"সফলভাবে সংশোধন করা হয়েছে",
	立即登录:"ইতিমধ্যে একটি অ্যাকাউন্ট আছে, লগ ইন করতে ক্লিক করুন",
	客服:"চ্যাট",
	邮箱:"ইমেইল",
	机器人量化AI交易收益:"রোবট এআই ট্রেডিং আয়ের পরিমাণ নির্ধারণ করে",
	体验机器人不能赠送:"অভিজ্ঞতা রোবট দূরে দেওয়া যাবে না",
	接受:"গ্রহণ করুন",
	拒绝:"প্রত্যাখ্যান করুন",
	语言切换:"ভাষা সুইচা",
}
