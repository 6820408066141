export default {
  tabbar: {
    首页: '집',
    市场: '매매',
    任务: '태스크',
    团队: '팀',
    我的: '나의'
  },
  navbar: {
    充值: '재충전',
    提现: '철수',
    帮助: '돕다',
    分享: '공유하다',
    活动: '이벤트'
  },	 
  payment: {
    付款方式详情: '결제 수단 세부정보',
    银行卡: '은행 카드',
    钱包地址: '지갑 주소',
    交易密码: '거래 비밀번호',
    保存: '저장'
  },
  market: {
    市场: '매매',
    名称: '이름',
    价格: '가격',
  },
 
  task: {
    任务: '직무',
	语言切换: '언어 전환',
    我的余额: '내 잔액',
    增加订单数量: '주문 수량 늘리기',
    今日剩余订单: '오늘 남은 주문',
    频率: '빈도',
    手动交易: '수동 거래',
    预期收益: '예상 수입',
    需要启动存款: '입금을 시작해야 합니다',
    任务说明: '과업 설명',
    每天可以完成10个任务: '하루에 10개의 작업을 완료할 수 있습니다.',
    联系VIP助手增加任务数量: '작업 수를 늘리려면 VIP 도우미에게 문의하세요.',
    每个用户的任务总数为300次: '사용자당 총 작업 수는 300개입니다.',
    购买机器人无需人工操作即可自动交易: '로봇 구매 시 수동 조작 없이 자동 거래',
    我知道了: '알겠어요!'
  },
  团队: '팀',
  规则: '규칙',
  会员级别: '회원 레벨',
  经验: '경험',
  代理信息: '에이전트 정보',
  更多: '더욱',
  团队奖励: '팀 보상',
  昨天: '어제',
  本星期: '이번 주',
  全部: '모두',
  团队收入记录: '팀 수익 기록',
  机器人回扣: '로봇 리베이트',
  会员体验: '회원 경험',
  个人收入记录: '개인 소득 기록',
  机器人采购: '로봇 조달',
  机器人启动押金: '로봇 시작 보증금',
  机器人礼物: '로봇 선물',
  机器人订单: '로봇 주문',
  机器人: '로봇',
  一次性总和: '일회성 합계',
  总计: '계',
  我的机器人: '내 로봇',
  被激活: '활성화',
  工作中: '일하고 있는',
  暂停: '중지',
  结束: '끝',
  刷新: '새로 고치다',
  来源: '~에서',
  价格: '가격',
  启动押金: '시작 가격',
  剩余时间: '남은 시간',
  激活时间: '활성화 시간',
  号: '아니.',
  我的资产: '내 자산',
  退出: '로그 아웃',
  邀请码: '초대 코드',
  我的钱包余额: '내 지갑 잔액',
  我的机器人仓库: '나의 로봇 창고',
  代理信息: '에이전트 정보',
  去提款: '철수',
  昨天代理返利: '어제 대행사 리베이트',
  累计代理返利: '누적 대행사 리베이트',
  今天机器人收益: '오늘 수익',
  累计机器人收益: '누적 소득',
  累计机器人回扣: '누적 리베이트',
  累计总回报: '누적 총 수익',
  自动订单机器人数量: '자동주문 로봇 대수',
  我的付款方式: '내 결제 수단',
  个人收入记录: '개인 소득 기록',
  团队收入记录: '팀 수익 기록',
  充值记录: '재충전기록',
  我的团队成员: '내 팀원들',
  活动中心: '이벤트 센터',
  修改登录密码: '로그인 비밀번호 수정',
  交易密码管理: '거래 비밀번호 관리',
  机器人: '로봇',
  机器人性能: '로봇 성능',
  机器人价格: '로봇 가격',
 
  有效期: '유효 기간',
  
  天: '일',
  去购买: '사다',
  买个机器人: '로봇 구매',
 
  预计日收益: '예상 일일 수입',
  启动保证金: '입금 시작',
  请输入购买数量: '구매 수량을 입력해주세요',
  个机器人: ' 대 / 로봇',
  机器人购买后需要激活才能工作: '구매 후 작동하려면 로봇을 활성화해야 합니다.',
  机器人激活需要从余额中扣除相应的启动押金:
    '로봇 활성화는 잔액에서 해당 시작 보증금을 공제해야 합니다.',
  机器人暂停机器人停止时将返还启动押金:
    '로봇이 멈추면 시작 보증금이 반환됩니다.',
	不激活的机器人可以赠送:"비활성 로봇을 버릴 수 있습니다.",
  购买: '구입하다',
  点击去了解我们: '우리에 대해 알아보려면 클릭하십시오',
  购买机器人: '로봇 구매',
  快速入口: '빠른 입장',
  '日/个人受益': '일일/개인 혜택',
  购买教程: '튜토리얼 구매',
  订单数量: '주문 수량',
  我们的合作伙伴: '우리의 파트너들',
  提款: '철수',
  提款金额: '출금 금액 ',
  你还没有添加银行卡: '은행 카드를 추가하지 않았습니다.',
  提现说明: '출금 설명',
  确认提款: '출금 확인 ',
  钱包余额: '지갑 잔액',
  输入数量:"USDT 금액 입력",
  数量:"양",
  登录: '로그인',
  输入账号: '계좌번호 입력',
  输入密码: '비밀번호 입력',
  还没有账号点击注册: '계정이 없습니까? 등록 클릭',
  忘记密码: '비밀번호를 잊으 셨나요',
  重新登录: '다시 로그인',
  密码找回成功: '비밀번호가 성공적으로 검색되었습니다.',
  找回成功请重新登录: '성공적으로 검색되었습니다. 다시 로그인하십시오.',
  确认: '확인하다',
  发送验证码: '인증 코드 보내기',
  再次输入密码: '비밀번호를 다시 입력하세요',
  输入验证码: '인증 코드 입력',
  输入手机号: '휴대폰 번호 입력',
  注册: '레지스터',
  输入用户名: '사용자 이름을 하나 입력',
  请输入邀请码: '초대 코드를 입력하세요',
  返回: '반품',
  密码找回失败: '비밀번호 검색 실패',
  输入的信息有误: '잘못된 정보를 입력했습니다',
	
	获取验证码:"코드 받기",
	手机号码不能为空:"휴대폰 번호는 비워둘 수 없습니다.",
	账号不存在:"계정이 존재하지 않습니다",
	发送成功:"성공적으로 보냈습니다",
	今日收益:"오늘의 수익",
	
	日收益:"일일 수입",
	
	数量不能为空:"수량은 비워둘 수 없습니다.",
	加载中:"로딩 중",
	唯一码:"고유 코드",
	未激活:"비활성",
	激活:"활성",
	购买时间:"구매 시간",
	"钱包余额不足，激活该机器人失败":"지갑 잔액 부족, 로봇 활성화 실패",
	暂无数据:"데이터 없음...",
	激活时间:"활성화 시간",
	运行时长:"실행 시간",
	签约购买:"계약 구매",
	系统赠送:"시스템 선물",
	状态:"지위",
	正常:"정상",
	我的机器人:"내 로봇",
	一级会员:"레벨 1",
	二级会员:"레벨 2",
	三级会员:"레벨 3",
	人:"명",
	充值客服:"고객 서비스 충전",
	充值:"재충전",
	提现:"철수",
	设置交易密码:"거래 비밀번호 설정",
	登录密码:"로그인 비밀번호",
	请输入交易密码:"거래 비밀번호를 입력하세요",
	再次请输入交易密码:"거래 비밀번호를 다시 입력하세요.",
	确认: '확인하다',
	手机号码不能为空:"휴대폰 번호는 비워둘 수 없습니다.",
	两次密码不一致:"두 암호가 일치하지 않습니다.",
	请输入验证码:"인증 코드를 입력 해주세요",
	操作成功:"작업이 성공했습니다.",
	"用户名或密码不正确,登录失败":"잘못된 사용자 이름 또는 암호, 로그인 실패",
	登录成功:"성공적 로그인",
	充值说明:"충전 지침",
	请先设置支付密码:"결제 비밀번호를 먼저 설정해주세요",
	复制成功:"복사 성공",
	冻结机器人做单本金:"로봇 주문의 주체를 동결",
	待审核:"검토 예정",
	成功:"성공",
	失败:"실패",
	审核中:"검토 중",
	在线充值:"온라인 충전",
	描叙:"설명하다",
	银行卡提现:"은행 카드 인출",
	USDT提现:"USDT 출금",
	三级代理:"세 번째 수준 에이전트",
	一级代理:"1차 에이전트",
	二级代理:"2차 에이전트",
	做单:"주문하기",
	团队返佣: "팀 리베이트",
	购买机器人返佣:"로봇 리베이트 구매",
	本金返还:"원금 반환",
	时间:"시각",
	机器人做单返还:"로봇이 주문을 하고 반환",
	涨幅:"증가하다",
	
  付款方式:"지불 방법",
	我的留言:"내 메시지",
  
  银行卡:'은행 카드',
  修改: '개정하다',
 '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'지갑 주소가 정확한지 확인하여 잘못된 주소 입력으로 인해 돈을 받을 수 없는 사용자로 인한 손실을 방지합니다.',
 连接借记卡:"체크카드 연결",
 银行名称:'은행 이름',
 姓名姓氏: '이름',
 IBAN号码:'은행 카드 번호',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* 중요: 인출하기 전에 직불카드 정보가 사실이고 유효해야 합니다..',
 帮助中心:'지원 센터',
 会员须知:'회원 참고 사항',
 '24小时内使用USDT实时取款（推荐）':'1. 24시간 이내 usdt로 실시간 출금(권장)',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2. 은행 카드 출금 * 출금시간은 10:00-18:00 * 출금 시작 후 2시간 이내 계좌입금 * 무출금 또는 은행간 출금 신청은 익일 10:00-18:00에 접수',
 我知道了:'알겠어요!',
 链接点击:'링크 클릭',
 
 建立自己的团队邀请:'나만의 팀 초대장 만들기',

 加入:'가입하다',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "귀하가 판매한 로봇은 상대방이 수락했습니다. 판매 수익이 귀하의 계정으로 지급되었습니다. 확인해주십시오.",
 	"你出售的机器人对方已拒绝接受":"귀하가 판매한 로봇이 상대방에 의해 거부되었습니다.",
 	向你赠送一台机器人:"확인을 위해 로봇이 제공됩니다.",
 	向你出售一台机器人:"확인을 위해 로봇을 판매합니다.",
 	出售价格不能超过机器人价格:"판매 가격은 로봇 가격을 초과할 수 없습니다.",
 	银行卡已被绑定:"은행 카드가 바인딩되었습니다.",
 	USDT_OMNI提现:'USDT_OMNI 출금',
 	USDT_ERC20提现:'USDT ERC20 출금',
 	银行卡提现:'은행 카드 인출',
 	邀请赠送:'초대 선물',
 	机器人返佣:'로봇 커미션',
 	升级:'업그레이드',
 	充值主网:'메인 네트워크 재충전',
   "您被限制出售机器人,请联系客服了解详情":
     "로봇 판매가 제한됩니다. 자세한 내용은 고객 서비스에 문의하십시오",
   交易编号: "거래 번호",
   消息详情:"메시지 세부정보",
   个人收益累计:"누적 개인 소득",
   今日代理收益:"오늘의 대행사 수입",
   代理累计收益:"대리인의 누적 소득",
  
   截图保存推荐给朋友:"스크린샷을 저장하고 친구에게 추천",
   复制:"복사",
   
   请输入正确的充值金额:"정확한 충전 금액을 입력하세요.",
   推荐:"추천하다",
   充值金额:"충전량",
   请上传付款截图:"결제 스크린샷을 업로드하세요.",
   充值ID提示:"충전 ID 프롬프트",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"지갑 주소가 올바른지 확인하십시오. 지갑 주소를 잘못 기입할 경우, 입금 불가로 인한 손실은 이용자 부담.",
	上传凭证:"바우처 업로드",
	不能为空:"비워둘 수 없습니다.",
  绑定钱包地址:'지갑 주소 바인딩',
	
	备注:"비고",
	请输入你的名字:"당신의 이름을 입력하세요",
	请输入银行卡号:"은행 카드 번호를 입력하세요.",
	银行卡号:"은행 카드 번호",
	银行:"은행",
	添加银行卡:"은행 카드 추가",
	请选择银行:"은행을 선택해 주세요",
	请输入钱包地址:"지갑주소를 입력해주세요",
	钱包地址:"지갑 주소",
	"取款须知":"철회 지침",
	"须知1":"1. 24시간 이내 usdt로 실시간 출금(권장)",
	"须知2":"2.은행 카드",
	"须知3":"출금 * 출금 시간은 10:00-18:00",
	"须知4":"*무출금시간 또는 은행간 출금 신청은 익일 10:00~18:00에 접수됩니다.",
	
	已绑定:"경계",
	去设置:"환경",
	汇率:"환율",
	请输入正确的提现金额:"정확한 출금금액을 입력해주세요",
	提款记录:"출금기록",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1: 바인딩된 지갑 주소는 지불 지갑과 일치해야 합니다. 그렇지 않으면 수신되지 않습니다.。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:충전 성공 후 약 1분 후에 도착할 것으로 예상됩니다. 지갑을 확인해주세요.",
	请先绑定钱包地址:"먼저 지갑 주소를 바인딩하십시오",
	输入邮箱:"이메일 입력",
	指定用户不存在:"지정된 사용자가 존재하지 않습니다",
	今日涨幅:"증가하다",
	快捷充币:"빠른 입금",
	快速买USDT:"빠른 구매 USDT",
	在线支付:"온라인 결제",
	自动到账:"자동 영수증",
	线下支付:"오프라인 결제",
	联系客服获取验证码:"인증 코드는 고객 서비스에 문의",
	获取:"얻다",
	重新登录:"다시 로그인",
	退出成功:"종료 성공",
	不能低于最小充值:"최소 충전량보다 낮을 수 없습니다.",
	不能低于最小提现金额:"최소 출금 금액 이상",
	最小提现:"최소 인출",
	官方充币:"공식 충전",
	银行卡充值:"은행 카드 충전",
	等待审核:"검토를 보류하다",
	可提现金额不足:"출금금액 부족",
	未经授权:"인증되지 않은",
	交易密码不正确:"잘못된 거래 비밀번호",
	提现次数不足:"불충분한 인출 시간",
	需要更新的银行卡不存在:"업데이트할 은행 카드가 존재하지 않습니다.",
	银行卡提现:"은행 카드 인출",
	激活金额:"활성화 금액",
	'赠送/出售':"제공/판매",
	赠送:"제공",
	出售:"판매",
	账户usdt不足:"균형 부족",
	请输入提现金额:"출금금액을 입력해주세요",
	恭喜新用户:"새로운 사용자를 축하합니다 ",
	kefu1:"유튜브 동영상 튜토리얼",
	登录密码:"로그인 비밀번호",
	再次请输入交易密码:"거래 비밀번호 다시 입력",
	输入手机号码:"전화 번호",
	输入手机号码:"전화번호 입력",
	输入密码:"비밀번호",
	请输入密码:"암호를 입력",
	手机号格式错误:"휴대폰 번호 형식 오류",
	"密码错误次数过多,请等待x秒后再试":"비밀번호 오류가 너무 많습니다. 600초 후에 다시 시도하십시오.",
	"此账号已冻结,请联系客服":"이 계정은 동결되었습니다. 고객 서비스에 문의하십시오",
	登录失败:"로그인 실패",
	请勿重复操作:"반복하지 마십시오",
	邀请码不正确:"잘못된 초대 코드",
	此账号已冻结:"이 계정은 동결되었습니다",
	请输入手机号:"휴대폰 번호를 입력해주세요",
	验证码错误:"인증 코드 오류",
	手机号存已存在:"휴대폰 번호가 이미 존재합니다.",
	注册账号已存在:"등록된 계정이 이미 존재합니다",
	请确定新密码:"새 비밀번호를 확인해 주세요",
	请再次输入登录密码:"로그인 비밀번호를 다시 입력하세요",
	密码长度不能少于6位:"비밀번호 길이는 6자리 이상이어야 합니다.",
	
	输入邮箱:"이메일 입력",
	注册邮箱已存在:"등록 사서함이 이미 있습니다.",
	请输入用户ID:"사용자 ID를 입력하세요.",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":
	"상대방의 계좌명을 입력하고 재산을 양도할 경우 정보를 잘 살펴보시기 바랍니다. 운영상의 오류가 있는 경우 시스템 또는 플랫폼 고객센터를 통해 조회할 수 없습니다..",
	请输入出售金额:"판매금액을 입력해주세요",
	出售中:"선물이 제출되었으며 확인을 기다리는 중입니다.",
	收到一台系统赠送的机器人 :"시스템에서 로봇을 받았습니다.",
	会员出售:"회원 판매",
	"出售待确认中,请勿重复操作":"확인 보류 중인 판매, 작업을 반복하지 마십시오.",
	激活成功:"성공을 활성화",
	操作成功:"작업이 성공했습니다.",
	
	输入邮箱:"이메일 입력",
	
	请输入登录密码:"로그인 비밀번호를 입력해주세요",
	未满足条件:"조건이 충족되지 않음",
	活动已过期:"활동 만료됨",
	个人充值奖励活动:"A: Personal deposit bonus event",
	"邀请好友注册，领取佣金":"친구를 초대하여 등록하고 커미션을 받으십시오.",
	领取:"받기 위해",
	团队规则:"팀 규칙",
	邮箱已存在:"이메일이 이미 존재합니다",
	获取邮箱验证码:"코드 받기",
	请输入邮箱:"이메일 주소를 입력하세요",
	手机号码:"핸드폰",
	邮箱不能为空:"이메일은 비워둘 수 없습니다.",
	"出售/赠送":"세일 / 선물",
	已接受:"수락됨",
	"出售/赠送人":"판매자 / 제공자",
	"向你赠送一台机器人，待确认":"확인을 위해 로봇이 제공됩니다.",
	国码:"국가 코드",
	取消:"취소",
	银行卡号位数错误:"잘못된 은행 카드 번호",
	官网:"공식 웹 사이트",
	注册成功:"성공적 로그인",
	设置成功:"성공적으로 설정",
	手机号码已存在:"휴대폰 번호가 이미 존재합니다.",
	修改成功:"성공적으로 수정됨",
	立即登录:"이미 계정이 있습니다. 로그인하려면 클릭하세요.",
	客服:"잡담",
	邮箱:"이메일",
	体验机器人不能赠送:"체험 로봇은 양도할 수 없습니다.",
	接受:"수용하다",
	拒绝:"거절하다",
}
