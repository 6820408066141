export default {
  tabbar: {
    首页: 'मुख्य पृष्ठ',
    市场: 'बाज़ार',
    任务: 'टास्क',
    团队: 'दल',
    我的: 'मेरे'
  },
  navbar: {
    充值: 'पुनर्भरण',
    提现: 'निकासी',
    帮助: 'मदद',
    分享: 'शेयर करना',
    活动: 'घटनाएँ'
  },	 
  payment: {
    付款方式详情: 'भुगतान विधि विवरण',
    银行卡: 'बैंक कार्ड',
    钱包地址: 'वॉलेट का पता',
    交易密码: 'लेन-देन पासवर्ड',
    保存: 'सहेजें'
  },
  market: {
    市场: 'बाज़ार',
    名称: 'नाम',
    价格: 'दाम',
  },
 
  task: {
    任务: 'टास्क',
	语言切换: 'भाषा स्विचिंग',
    我的余额: 'मेरा संतुलन',
    增加订单数量: 'आदेश मात्रा बढ़ाएँ',
    今日剩余订单: 'शेष आदेश आज',
    频率: 'आवृत्ति',
    手动交易: 'मैनुअल ट्रेडिंग',
    预期收益: 'अपेक्षित आय',
    需要启动存款: 'जमा शुरू करने की जरूरत है',
    任务说明: 'Task description',
    每天可以完成10个任务: 'Can complete 10 tasks per day',
    联系VIP助手增加任务数量: 'Contact the VIP assistant to increase the number of tasks',
    每个用户的任务总数为300次: 'The total number of tasks per user is 300',
    购买机器人无需人工操作即可自动交易: 'रोबोट खरीदते समय मैन्युअल ऑपरेशन के बिना स्वचालित लेनदेन',
    我知道了: 'मैं समझ गया!'
  },
  团队: 'दल',
  规则: 'नियम',
  会员级别: 'सदस्य स्तर',
  经验: 'अनुभव',
  代理信息: 'एजेंट की जानकारी',
  更多: 'अधिक',
  团队奖励: 'टीम इनाम',
  昨天: 'बिता कल',
  本星期: 'इस सप्ताह',
  全部: 'सभी',
  团队收入记录: 'टीम राजस्व रिकॉर्ड',
  机器人回扣: 'रोबोट कमीशन',
  会员体验: 'सदस्य अनुभव',
  个人收入记录: 'व्यक्तिगत आय रिकॉर्ड',
  机器人采购: 'रोबोट खरीद',
  机器人启动押金: 'रोबोट स्टार्टअप डिपॉजिट',
  机器人礼物: 'रोबोट उपहार',
  机器人订单: 'रोबोट ऑर्डर',
  机器人: 'रोबोट',
  一次性总和: 'एकमुश्त',
  总计: 'टोटल',
  我的机器人: 'मेरा रोबोट',
  被激活: 'सक्रिय',
  工作中: 'कार्यरत',
  暂停: 'रुकना',
  结束: 'समाप्त',
  刷新: 'ताज़ा करना',
  来源: 'स्रोत',
  价格: 'दाम',
  启动押金: 'मूल्य शुरू करें',
  剩余时间: 'बचा हुआ समय',
  激活时间: 'सक्रियण समय',
  号: 'नहीं।',
  我的资产:'मेरी संपत्ति',
  退出: 'लॉग आउट',
  邀请码: 'निमंत्रण कोड',
  我的钱包余额: 'माई वॉलेट बैलेंस',
  我的机器人仓库: 'मेरा रोबोट गोदाम',
  代理信息: 'एजेंट की जानकारी',
  去提款: 'निकासी',
  昨天代理返利: 'एजेंसी की आय कल',
  累计代理返利: 'संचित एजेंसी आय',
  今天机器人收益: 'आज का राजस्व',
  累计机器人收益: 'संचित आय',
  累计机器人回扣: 'संचित कमीशन',
  累计总回报: 'संचयी कुल रिटर्न',
  自动订单机器人数量: 'स्वचालित आदेश रोबोटों की संख्या',
  我的付款方式: 'मेरी भुगतान विधि',
  个人收入记录: 'व्यक्तिगत आय रिकॉर्ड',
  团队收入记录: 'टीम राजस्व रिकॉर्ड',
  充值记录: 'रिचार्ज रिकॉर्ड',
  我的团队成员: 'मेरी टीम के सदस्य',
  活动中心: 'घटना केंद्र',
  修改登录密码: 'लॉगिन पासवर्ड संशोधित करें',
  交易密码管理: 'लेनदेन पासवर्ड प्रबंधन',
  机器人: 'रोबोट',
  机器人性能: 'रोबोट प्रदर्शन',
  机器人价格: 'रोबोट की कीमत',
 
  有效期: 'वैधता अवधि',
  
  天: 'दिन',
  去购买: 'ख़रीदना',
  买个机器人: 'रोबोट खरीदें',
 
  预计日收益: 'अनुमानित दैनिक आय',
  启动保证金: 'स्टार्ट अप डिपॉजिट',
  请输入购买数量: 'कृपया खरीद मात्रा दर्ज करें',
  个机器人: ' /रोबोट',
  机器人购买后需要激活才能工作: 'खरीद के बाद काम करने के लिए रोबोट को सक्रिय करने की आवश्यकता है',
  机器人激活需要从余额中扣除相应的启动押金:
    'रोबोट सक्रियण को शेष राशि से संबंधित स्टार्टअप जमा को घटाना होगा',
  机器人暂停机器人停止时将返还启动押金:
    'जब रोबोट बंद हो जाता है, तो स्टार्टअप जमा वापस कर दिया जाएगा',
	不激活的机器人可以赠送:"निष्क्रिय रोबोटों को दिया जा सकता है",
  购买: 'खरीदें',
  点击去了解我们: 'हमारे बारे में जानने के लिए क्लिक करें',
  购买机器人: 'रोबोट खरीदें',
  快速入口: 'त्वरित प्रविष्टि',
  '日/个人受益': 'दैनिक / व्यक्तिगत लाभ',
  购买教程: 'ट्यूटोरियल खरीदें',
  订单数量: 'आदेश की मात्रा',
  我们的合作伙伴: 'हमारे सहयोगियों',
  提款: 'निकासी',
  提款金额: 'निकाली गयी राशि ',
  你还没有添加银行卡: 'आपने बैंक कार्ड नहीं जोड़ा है',
  提现说明: 'निकासी विवरण',
  确认提款: 'निकासी की पुष्टि करें ',
  钱包余额: 'वॉलेट बैलेंस',
  输入数量:"यूएसडीटी राशि दर्ज करें",
  数量:"मात्रा",
  登录: 'लॉग इन करें',
  输入账号: 'खाता संख्या दर्ज करें',
  输入密码: 'इनपुट पासवर्ड',
  还没有账号点击注册: 'खाता नहीं? रजिस्टर पर क्लिक करें',
  忘记密码: 'पासवर्ड भूल गए',
  重新登录: 'फिर से लॉगिन करें',
  密码找回成功: 'पासवर्ड सफलतापूर्वक पुनर्प्राप्त किया गया',
  找回成功请重新登录: 'सफलतापूर्वक पुनर्प्राप्त, कृपया फिर से लॉग इन करें',
  确认: 'पुष्टि करें',
  发送验证码: 'सत्यापन कोड भेजें',
  再次输入密码: 'पासवर्ड फिर से दर्ज करें',
  输入验证码: 'सत्यापन कोड दर्ज करें',
  输入手机号: 'मोबाइल नंबर दर्ज करें',
  注册: 'पंजीकरण करवाना',
  输入用户名: 'एक उपयोगकर्ता नाम दर्ज करें',
  请输入邀请码: 'कृपया आमंत्रण कोड दर्ज करें',
  返回: 'वापसी',
  密码找回失败: 'पासवर्ड पुनर्प्राप्ति विफल',
  输入的信息有误: 'गलत जानकारी दर्ज की गई',
	
	获取验证码:"कोड प्राप्त करें",
	手机号码不能为空:"मोबाइल नंबर खाली नहीं हो सकता",
	账号不存在:"खाता मौजूद नहीं हैं",
	发送成功:"सफलतापूर्वक भेज दिया",
	今日收益:"आज की कमाई",
	
	日收益:"दैनिक आय",
	
	数量不能为空:"मात्रा खाली नहीं हो सकती",
	加载中:"लोडिंग。。。",
	唯一码:"अद्वितीय कोड",
	未激活:"सक्रिय नहीं",
	激活:"सक्रियण",
	购买时间:"खरीद समय",
	"钱包余额不足，激活该机器人失败":"अपर्याप्त वॉलेट बैलेंस, रोबोट को सक्रिय करने में विफल",
	暂无数据:"कोई डेटा नहीं...",
	激活时间:"सक्रियण समय",
	运行时长:"रन टाइम",
	签约购买:"अनुबंध खरीद",
	系统赠送:"सिस्टम उपहार",
	状态:"राज्य",
	正常:"सामान्य",
	我的机器人:"मेरा रोबोट",
	一级会员:"स्तर 1",
	二级会员:"स्तर 2",
	三级会员:"स्तर 3",
	人:"लोग",
	充值客服:"ग्राहक सेवा रिचार्ज",
	充值:"फिर से दाम लगाना",
	提现:"निकासी",
	设置交易密码:"लेनदेन पासवर्ड सेट करें",
	登录密码:"लॉग इन पासवर्ड",
	请输入交易密码:"कृपया लेनदेन पासवर्ड दर्ज करें",
	再次请输入交易密码:"कृपया अपना लेनदेन पासवर्ड दोबारा दर्ज करें",
	确认: 'पुष्टि करें',
	手机号码不能为空:"मोबाइल नंबर खाली नहीं हो सकता",
	两次密码不一致:"दो पासवर्ड असंगत हैं",
	请输入验证码:"कृपया सत्यापन कोड दर्ज करें",
	操作成功:"ऑपरेशन सफल",
	"用户名或密码不正确,登录失败":"गलत उपयोगकर्ता नाम या पासवर्ड, लॉगिन विफल",
	登录成功:"लॉगिन सफलतापूर्वक",
	充值说明:"रिचार्ज निर्देश",
	请先设置支付密码:"कृपया पहले भुगतान पासवर्ड सेट करें",
	复制成功:"सफलतापूर्वक कॉपी करें",
	冻结机器人做单本金:"रोबोट ऑर्डर के प्रिंसिपल को फ्रीज करना",
	待审核:"समीक्षा की जानी है",
	成功:"सफलता",
	失败:"विफल",
	审核中:"समीक्षा के अंतर्गत",
	在线充值:"ऑनलाइन रिचार्ज",
	描叙:"वर्णन करना",
	银行卡提现:"बैंक कार्ड निकासी",
	USDT提现:"यूएसडीटी निकासी",
	三级代理:"तीसरे स्तर का एजेंट",
	一级代理:"प्राथमिक एजेंट",
	二级代理:"माध्यमिक एजेंट",
	做单:"एक आदेश कर",
	团队返佣: "टीम आय",
	购买机器人返佣:"रोबोट आय खरीदें",
	本金返还:"मूलधन की वापसी",
	时间:"समय",
	机器人做单返还:"रोबोट आदेश देता है और लौटाता है",
	涨幅:"बढ़ाएँ",
	
  付款方式:"भुगतान विधि",
	我的留言:"मेरा संदेश",
  
  银行卡:'बैंक कार्ड',
  修改: 'संशोधित करें',
 '确保钱包地址正确，钱包以防地址输入错误而收不到钱用户造成的损失':'सुनिश्चित करें कि वॉलेट का पता सही है, ताकि उन उपयोगकर्ताओं को होने वाले नुकसान को रोका जा सके जो गलत पता इनपुट के कारण धन प्राप्त नहीं कर सकते हैं',
 连接借记卡:"डेबिट कार्ड कनेक्ट करें",
 银行名称:'बैंक का नाम',
 姓名姓氏: 'नाम',
 IBAN号码:'बैंक कार्ड नंबर',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'*महत्वपूर्ण: निकासी से पहले डेबिट कार्ड की जानकारी सही और वैध होनी चाहिए.',
 帮助中心:'सहायता केंद्र',
 会员须知:'सदस्यों के लिए नोट्स',
 '24小时内使用USDT实时取款（推荐）':'1. 24 घंटे के भीतर यूएसडीटी के साथ वास्तविक समय निकासी (अनुशंसित)',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2. बैंक कार्ड निकासी * निकासी का समय 10: 00-18: 00 है * निकासी शुरू होने के 2 घंटे के भीतर खाता प्रविष्टि * गैर निकासी समय या अंतर-बैंक निकासी के लिए आवेदन अगले दिन 10:00-18:00 बजे प्राप्त होगा',
 我知道了:'मैं समझ गया!',
 链接点击:'लिंक क्लिक',
 
 建立自己的团队邀请:'अपनी खुद की टीम आमंत्रण बनाएं',

 加入:'शामिल हों',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "आपके द्वारा बेचा गया रोबोट दूसरे पक्ष द्वारा स्वीकार कर लिया गया है। बिक्री की आय का भुगतान आपके खाते में कर दिया गया है। कृपया जांचें.",
 	"你出售的机器人对方已拒绝接受":"आपके द्वारा बेचा गया रोबोट दूसरे पक्ष द्वारा अस्वीकार कर दिया गया है",
 	向你赠送一台机器人:"पुष्टि के लिए आपके लिए एक रोबोट प्रस्तुत किया गया है",
 	向你出售一台机器人:"पुष्टि के लिए आपको एक रोबोट बेचें",
 	出售价格不能超过机器人价格:"बिक्री मूल्य रोबोट की कीमत से अधिक नहीं हो सकता",
 	银行卡已被绑定:"बैंक कार्ड बाध्य हो गया है",
 	USDT_OMNI提现:'USDT_OMNI निकासी',
 	USDT_ERC20提现:'USDT ERC20 निकासी',
 	银行卡提现:'बैंक कार्ड निकासी',
 	邀请赠送:'निमंत्रण उपहार',
 	机器人返佣:'रोबोट आयोग',
 	升级:'उन्नत करना',
	银行:"बैंक",
 	充值主网:'मुख्य नेटवर्क रिचार्ज',
   "您被限制出售机器人,请联系客服了解详情":
     "आप रोबोट बेचने से प्रतिबंधित हैं। जानकारी के लिए ग्राहक सेवा से संपर्क करें",
   交易编号: "लेन-देन संख्या",
   消息详情:"संदेश विवरण",
   个人收益累计:"संचित व्यक्तिगत आय",
   今日代理收益:"एजेंसी की आय आज",
   代理累计收益:"एजेंसी से संचित आय",
  
   截图保存推荐给朋友:"स्क्रीनशॉट सहेजें और मित्रों को अनुशंसा करें",
   复制:"प्रतिलिपि",
   
   请输入正确的充值金额:"कृपया सही रीचार्ज राशि दर्ज करें",
   推荐:"अनुशंसा करना",
   充值金额:"रिचार्ज राशि",
   请上传付款截图:"कृपया भुगतान स्क्रीनशॉट अपलोड करें",
   充值ID提示:"रिचार्ज आईडी प्रॉम्प्ट",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"कृपया सुनिश्चित करें कि वॉलेट का पता सही है। यदि वॉलेट का पता गलत तरीके से भरा गया है, तो उपयोगकर्ता धन एकत्र करने में असमर्थता के कारण होने वाले नुकसान को वहन करेगा.",
	上传凭证:"वाउचर अपलोड करें",
	不能为空:"खाली नहीं किया जा सकता",
  绑定钱包地址:'बटुए का पता बांधें',
	
	备注:"रिमार्क्स",
	请输入你的名字:"अपना नाम दर्ज करें",
	请输入银行卡号:"कृपया बैंक कार्ड नंबर दर्ज करें",
	银行卡号:"बैंक कार्ड नंबर",
	添加银行卡:"बैंक कार्ड जोड़ें",
	请选择银行:"कृपया एक बैंक चुनें",
	请输入钱包地址:"कृपया वॉलेट पता दर्ज करें",
	钱包地址:"वॉलेट का पता",
	"取款须知":"निकासी के निर्देश",
	"须知1":"1. Real time withdrawal with usdt within 24 hours (recommended)",
	"须知2":"2.bank card",
	"须知3":"Withdrawal * withdrawal time is 10:00-18:00",
	"须知4":"*The application for non withdrawal time or inter-bank withdrawal will be received at 10:00-18:00 the next day",
	
	已绑定:"अवश्यंभावी",
	去设置:"स्थापना",
	汇率:"विनिमय दर",
	请输入正确的提现金额:"कृपया निकासी की सही राशि दर्ज करें",
	提款记录:"निकासी रिकॉर्ड",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"टिप 1: बाध्य वॉलेट पता भुगतान वॉलेट के अनुरूप होना चाहिए, अन्यथा यह प्राप्त नहीं होगा。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"टिप 2: सफल रिचार्ज के लगभग एक मिनट बाद आने की उम्मीद है। कृपया अपना बटुआ जांचें.",
	请先绑定钱包地址:"कृपया पहले बटुए का पता बाँधें",
	输入邮箱:"जीमेल दर्ज करें",
	指定用户不存在:"निर्दिष्ट उपयोगकर्ता मौजूद नहीं है",
	今日涨幅:"बढ़ोतरी",
	快捷充币:"त्वरित जमा",
	快速买USDT:"जल्दी खरीदें USDT",
	在线支付:"ऑनलाइन भुगतान",
	自动到账:"स्वचालित रसीद",
	线下支付:"ऑफलाइन भुगतान",
	联系客服获取验证码:"सत्यापन कोड के लिए ग्राहक सेवा से संपर्क करें",
	获取:"प्राप्त",
	重新登录:"फिर से लॉगिन करें",
	退出成功:"बाहर निकलें सफल",
	不能低于最小充值:"न्यूनतम रिचार्ज से कम नहीं हो सकता",
	不能低于最小提现金额:"न्यूनतम निकासी राशि से कम नहीं",
	最小提现:"न्यूनतम निकासी",
	官方充币:"आधिकारिक चार्ज",
	银行卡充值:"बैंक कार्ड रिचार्ज",
	等待审核:"लंबित समीक्षा",
	可提现金额不足:"अपर्याप्त निकासी राशि",
	未经授权:"निषिद्ध",
	交易密码不正确:"गलत लेनदेन पासवर्ड",
	提现次数不足:"अपर्याप्त निकासी समय",
	需要更新的银行卡不存在:"अद्यतन किया जाने वाला बैंक कार्ड मौजूद नहीं है",
	银行卡提现:"बैंक कार्ड निकासी",
	激活金额:"सक्रियण राशि",
	'赠送/出售':"देना / बेचना",
	赠送:"देना",
	出售:"बेचना",
	账户usdt不足:"संतुलन की कमी",
	请输入提现金额:"कृपया निकासी राशि दर्ज करें",
	恭喜新用户:"नए उपयोगकर्ताओं को बधाई",
	kefu1:"यूट्यूब वीडियो ट्यूटोरियल",
	登录密码:"लॉग इन पासवर्ड",
	再次请输入交易密码:"लेनदेन पासवर्ड फिर से दर्ज करें",
	输入手机号码:"फ़ोन नंबर",
	输入手机号码:"फोन नंबर दर्ज",
	输入密码:"पासवर्ड",
	请输入密码:"पास वर्ड दर्ज करें",
	手机号格式错误:"मोबाइल नंबर प्रारूप त्रुटि",
	"密码错误次数过多,请等待x秒后再试":"बहुत अधिक पासवर्ड त्रुटियाँ, कृपया 600 सेकंड प्रतीक्षा करें और पुनः प्रयास करें",
	"此账号已冻结,请联系客服":"यह खाता फ्रीज कर दिया गया है, कृपया ग्राहक सेवा से संपर्क करें",
	登录失败:"लॉगिन विफल",
	请勿重复操作:"दोहराना नहीं",
	邀请码不正确:"गलत आमंत्रण कोड",
	此账号已冻结:"इस खाते को फ्रीज कर दिया गया है",
	请输入手机号:"अपना मोबाइल नंबर दर्ज करें",
	验证码错误:"सत्यापन कोड त्रुटि",
	手机号存已存在:"मोबाइल फ़ोन नंबर पहले से मौजूद है",
	注册账号已存在:"पंजीकृत खाता पहले से मौजूद है",
	请确定新密码:"कृपया नए पासवर्ड की पुष्टि करें",
	请再次输入登录密码:"कृपया अपना लॉगिन पासवर्ड फिर से दर्ज करें",
	密码长度不能少于6位:"पासवर्ड की लंबाई 6 अंकों से कम नहीं हो सकती",
	
	输入邮箱:"जीमेल दर्ज करें",
	注册邮箱已存在:"पंजीकरण जीमेल पहले से मौजूद है",
	请输入用户ID:"कृपया उपयोगकर्ता आईडी दर्ज करें",
	"输入对方账户名称，转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。":
	"यदि आप दूसरे पक्ष के खाते का नाम दर्ज करते हैं और संपत्ति हस्तांतरित करते हैं तो कृपया जानकारी की सावधानीपूर्वक समीक्षा करें। यदि कोई ऑपरेशन त्रुटि है, तो इसे सिस्टम या प्लेटफ़ॉर्म ग्राहक सेवा के माध्यम से पुनर्प्राप्त नहीं किया जा सकता है.",
	请输入出售金额:"कृपया बिक्री राशि दर्ज करें",
	出售中:"उपहार सबमिट कर दिया गया है, पुष्टि की प्रतीक्षा में",
	 收到一台系统赠送的机器人 :"सिस्टम से एक रोबोट प्राप्त किया",
	 会员出售:"सदस्य बेचते हैं",
	 "出售待确认中,请勿重复操作":"बिक्री की पुष्टि लंबित है, ऑपरेशन दोबारा न करें",
	 激活成功:"सफलता को सक्रिय करें",
	 操作成功:"ऑपरेशन सफल",
	
	输入邮箱:"जीमेल दर्ज करें",
	
	请输入登录密码:"कृपया अपना लॉगिन पासवर्ड दर्ज करें",
	未满足条件:"शर्त पूरी नहीं हुई",
	活动已过期:"गतिविधि समाप्त हो गई",
	个人充值奖励活动:"A: Personal deposit bonus event",
	"邀请好友注册，领取佣金":"रजिस्टर करने और कमीशन प्राप्त करने के लिए दोस्तों को आमंत्रित करें",
	领取:"ग्रहण करना",
	团队规则:"टीम नियम",
	邮箱已存在:"जीमेल पहले से मौजूद है",
	获取邮箱验证码:"कोड प्राप्त करें",
	请输入邮箱:"कृपया ईमेल पता दर्ज करें",
	手机号码:"फ़ोन",
	邮箱不能为空:"ईमेल खाली नहीं हो सकता",
	"出售/赠送":"बिक्री / उपहार",
	已接受:"को स्वीकृत",
	"出售/赠送人":"विक्रेता / दाता",
	"向你赠送一台机器人，待确认":"पुष्टि के लिए आपके लिए एक रोबोट प्रस्तुत किया गया है",
	国码:"कंट्री कोड",
	取消:"रद्द करना",
	银行卡号位数错误:"बैंक कार्डों की गलत संख्या",
	官网:"आधिकारिक साइट",
	注册成功:"सफलतापूर्ण प्रवेश",
	设置成功:"सफलतापूर्वक सेट करें",
	手机号码已存在:"मोबाइल नंबर पहले से मौजूद है",
	修改成功:"सफलतापूर्वक संशोधित",
	立即登录:"पहले से ही एक खाता है, लॉग इन करने के लिए क्लिक करें",
	客服:"चैट",
	邮箱:"ईमेल",
	机器人量化AI交易收益:"रोबोट एआई ट्रेडिंग आय की मात्रा निर्धारित करता है",
	体验机器人不能赠送:"अनुभव रोबोट नहीं दिए जा सकते",
	接受:"स्वीकार करना",
	拒绝:"इनकार",
	
}
